import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, Spinner } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import {
    registerUser,
    resetRegisterFlag
} from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate, useLocation } from "react-router-dom";

//import images 
import logoLight from "../../assets/images/logo-bar.webp";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { createSelector } from "reselect";

import { CreateUserRequest, UserStoreApproval } from "../../types/Auth";

const Register = () => {
    const history = useNavigate();
    const dispatch: any = useDispatch();
    const location = useLocation();

    // Parse URL parameters
    const searchParams = new URLSearchParams(location.search);
    const store_id = searchParams.get('store_id');
    const registration_token = searchParams.get('registration_token');
    const platform_type = searchParams.get('platform_type');

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Por favor ingresa tu e-mail"),
            firstName: Yup.string().required("Por favor ingresa tu nombre"),
            lastName: Yup.string().required("Por favor ingresa tu apellido"),
            password: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                    "Debe contener al menos 8 caracteres, una letra mayúscula, una minúscula y números."
                ),

            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password")], "Las claves no coinciden")
                .required("Por favor confirma tu clave"),
        }),
        onSubmit: (values) => {
            const userData: CreateUserRequest = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password: values.password,
            };

            const storeApprovalData: UserStoreApproval = {
                store_id: store_id || undefined,
                registration_token: registration_token || undefined,
                platform_type: platform_type || undefined,
            };

            dispatch(registerUser(userData, storeApprovalData));
        }
    });


    const selectLayoutState = (state: any) => state.Account;
    const registerdatatype = createSelector(
        selectLayoutState,
        (account) => ({
            success: account.success,
            error: account.error,
            registrationError: account.registrationError,
            loading: account.loading,
        })
    );

    const {
        error, success, loading, registrationError,
    } = useSelector(registerdatatype);

    useEffect(() => {
        if (success) {
            setTimeout(() => history("/login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);
    }, [dispatch, success, error, history]);

    document.title = "Registro | Burbuxa";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-4">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-4 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="80" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium"></p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Crear una cuenta</h5>
                                            <p className="text-muted">Regístrate para poder acceder a Burbuxa</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">

                                                {success ? (
                                                    <>
                                                        {toast("Redireccionando al ingreso...", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}
                                                        <ToastContainer autoClose={2000} limit={1} />
                                                        <Alert color="success">
                                                            Registración completada con éxito, serás redireccionado a la pantalla de ingreso...
                                                        </Alert>
                                                    </>
                                                ) : null}

                                                {error ? (
                                                    <Alert color="danger"><div>{registrationError}</div></Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <Label htmlFor="useremail" className="form-label">E-mail <span className="text-danger">*</span></Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Ingrese su e-mail"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="firstName" className="form-label">Nombre<span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="Ingrese su nombre"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.firstName || ""}
                                                        invalid={
                                                            validation.touched.firstName && validation.errors.firstName ? true : false
                                                        }
                                                    />
                                                    {validation.touched.firstName && validation.errors.firstName ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.firstName}</div></FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="lastName" className="form-label">Apellido<span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Ingrese su apellido"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.lastName || ""}
                                                        invalid={
                                                            validation.touched.lastName && validation.errors.lastName ? true : false
                                                        }
                                                    />
                                                    {validation.touched.lastName && validation.errors.lastName ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.lastName}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">Clave <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="password"
                                                        type="password"
                                                        placeholder="Ingrese su clave"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                <div className="mb-2">
                                                    <Label htmlFor="confirmPassword" className="form-label">Re-ingrese la clave <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="confirmPassword"
                                                        type="password"
                                                        placeholder="Re-ingrese la clave"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.confirmPassword || ""}
                                                        invalid={
                                                            validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                        }
                                                    />
                                                    {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.confirmPassword}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                <p className="mt-4 text-center">Al registrarte, estás aceptando nuestros <a href="https://burbuxa.com/terms-of-service" target="_blank" rel="noopener noreferrer">Términos de Servicio</a> y nuestra <a href="https://burbuxa.com/privacy-policy" target="_blank" rel="noopener noreferrer">Política de Privacidad</a>.</p>

                                                <div className="mt-4">
                                                    <Button color="success" className="w-100" type="submit" disabled={loading}>
                                                        {loading && <Spinner size="sm" className='me-2'> Cargando... </Spinner>}
                                                        Crear cuenta
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">¿Ya tienes una cuenta? <Link to="/login" className="fw-semibold text-primary text-decoration-underline">Ingresar</Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Register;
