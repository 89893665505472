import DisplayDataCard from 'Components/Common/DisplayDataCard'
import { Col, Container, Row } from 'reactstrap'

import { useSelector } from 'react-redux'
import { selectProfile } from 'selectors'

const CampaignTransactionalMetrics = ({ currency, report }: any) => {
    const { currentStoreCurrency } = useSelector(selectProfile)

    return <Container fluid>
        <Row>
            <Col>
                <div className="h-100">
                    <Row>
                        <Col sm={6} md={3}>
                            <DisplayDataCard
                                value={report?.totalMessagesSent}
                                label='Mensajes enviados'
                                bgIconColor="danger"
                                icon="bx bx-message-dots"
                            />
                        </Col>
                        <Col sm={6} md={3}>
                            <DisplayDataCard
                                value={currency == 'USD' ? report?.totalInvestment : report?.totalInvestmentStore}
                                label='Inversión'
                                prefix={currency == 'USD' ? 'USD ' : currentStoreCurrency}
                                bgIconColor="warning"
                                icon="bx bx-money"
                            />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </Container>
}

export default CampaignTransactionalMetrics