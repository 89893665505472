// src/slices/auth/forgetpwd/thunk.ts

import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer";
import {
    postJwtForgetPwd,
} from "../../../helpers/backend_helper";

export const userForgetPassword = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        response = postJwtForgetPwd(user.email);

        const data = await response;

        if (data) {
            dispatch(
                userForgetPasswordSuccess(
                    "Si tu usuario existía, el enlace de restablecimiento ha sido enviado a tu correo electrónico, verifica tu bandeja de entrada."
                )
            );
        }
    } catch (forgetError) {
        dispatch(userForgetPasswordError(forgetError));
    }
};