import { Table, } from "reactstrap";
import Pagination from 'Components/Common/Pagination';
import { GenericContact } from "types/Dashboard";

interface ContactTableProps {
    contacts: GenericContact[]
    limit: number
    skip: number
    total: number
    onSelectContact: (contact: GenericContact) => void
    fetchContacts: (skip: number) => void
}

const ContactTable = ({ contacts, limit, skip, total, fetchContacts, onSelectContact }: ContactTableProps) => {
    return <div className="table-responsive">
        <Table className="align-middle table-nowrap mb-0" striped hover>
            <thead className="table-light">
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Teléfono</th>
                </tr>
            </thead>
            <tbody>
                {contacts.map((contact: GenericContact) => (
                    <tr
                        key={contact.id}
                        onClick={() => onSelectContact(contact)}
                        style={{ cursor: "pointer" }}
                    >
                        <td>{contact.first_name} {contact.last_name}</td>
                        <td>{contact.phone}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <Pagination
            total={limit !== 0 ? Math.ceil(total / limit) : 0}
            data={contacts}
            setCurrentPage={(page: any) => fetchContacts((page - 1) * limit)}
            currentPage={limit !== 0 ? Math.ceil(skip / limit) + 1 : 0}
            perPageData={limit}
        />
    </div>
}

export default ContactTable;