import CampaignForm from 'Components/Campaigns/CampaignForm'
import CampaignModal from 'Components/Campaigns/CampaignModal'
import BreadCrumb from 'Components/Common/BreadCrumb'
import useCurrentStore from 'Components/Hooks/CurrentStore'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import { selectCampaigns, selectProfile } from 'selectors'
import { createCampaign, createCampaignBudget } from 'slices/campaign/thunk'


const CampaignEdit = () => {
    useCurrentStore()
    const { id } = useParams()
    const navigate = useNavigate()
    const [values, setValues] = useState<any>({});
    const [file, setFile] = useState<any>(null);
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const { campaign, budget, status, redirToList } = useSelector(selectCampaigns)
    const [showCampaignModal, setShowCampaignModal] = useState(false)

    const toggleCampaignModal = () => setShowCampaignModal(!showCampaignModal)

    document.title = `Crear campaña | Burbuxa`

    const onSubmit = (values: any, file?: File, showBudget: boolean = true) => {
        setValues(values);
        const nValues = { ..._cleanValues(values), status }
        if (showBudget) {
            setShowCampaignModal(true)
            dispatch(
                createCampaignBudget({ storeId: currentStoreId, payload: nValues })
            )
        } else {
            onSave(undefined, nValues)
        }
    }

    const _cleanValues = (values: any) => {
        const nValues = { ...values }
        Object.keys(nValues).map((key) => {
            if (nValues[key] === "") {
                nValues[key] = null
            }
        })
        return nValues
    }

    useEffect(() => {
        if (redirToList) {
            navigate('/campaigns/')
        }
    }, [redirToList])

    const onSave = (status: any, pValues?: any) => {
        let file;
        const nValues = {
            ...(pValues ?? _cleanValues(values)),
            status,
        }

        if (nValues.file) {
            file = nValues.file
            delete nValues['file']
        } else {
            file = null
        }

        dispatch(
            createCampaign({
                storeId: currentStoreId,
                payload: nValues,
                file,
            })
        )
    }

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Crear campaña" pageTitle="Campañas" />
                <CampaignForm
                    campaign={{}}
                    onSubmit={(values: any, f?: File) => {
                        onSubmit(values, f, values.rule !== 'IF_ABANDONED')
                    }}
                />
            </Container>
        </div>
        {status === 'loaded' && budget !== null && <CampaignModal
            toggle={toggleCampaignModal}
            isOpen={showCampaignModal}
            budget={budget}
            params={values}
            onActivate={values.scheduled_at ? null : () => onSave('ACTIVE')}
            onSave={() => onSave('INACTIVE')}
            title="Presupuesto de campaña"
        />}
    </React.Fragment>
}

export default CampaignEdit