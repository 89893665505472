import { useNavigate } from "react-router-dom";
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { Ticket, TicketContext, TicketStatusColumns } from "types/Dashboard"


const TicketItem = (ticket: Ticket, onArchiveTicket: (ticket: Ticket) => void, navigate: any, onUserAssignation: (ticket: Ticket) => void) => {
    return {
        id: ticket.id,
        title: `${ticket.conversation_contact?.phone} / ${ticket.conversation_contact?.name}`,
        header: ticket.assigned_user ? <Badge color="info" className="p-1 fs-10" style={{ height: '17px', lineHeight: 'auto' }}>
            {ticket.assigned_user.first_name} {ticket.assigned_user.last_name}
        </Badge> : <div className="text-muted p-1 fs-10">Sin asignar</div>,
        action: <div className="d-flex flex-row gap-2 justify-content-center">
            <Button
                size="sm"
                className="mt-1 p-1 pt-0 pb-0"
                title="Ir al chat"
                color="primary"
                outline
                onClick={() => navigate(`/chat/conversations?phone=${encodeURIComponent(ticket.conversation_contact?.phone)}`)}
            >
                <i className="ri-whatsapp-line"></i>
            </Button>
            <Button size="sm"
                className="mt-1 p-1 pt-0 pb-0 ml-2"
                title="Archivar ticket"
                color="danger"
                outline
                onClick={() => onArchiveTicket(ticket)}
            >
                <i className="ri-archive-line"></i>
            </Button>
        </div>,
        description: <div className="fs-9 d-flex flex-column gap-1">
            {ticket.text}
            <small className="text-muted">{new Date(ticket.created_at).toLocaleDateString()}</small>
        </div>,
        columnIndex: TicketStatusColumns.indexOf(ticket.status),
        dropdown: <DropdownMenu>
            <DropdownItem onClick={() => onUserAssignation(ticket)}>
                <i className="ri-user-add-line"></i>{' '}Asignar usuario
            </DropdownItem>
        </DropdownMenu>,
        content: <div className="d-flex flex-column">
            {ticket.context.map((context: TicketContext) => <div key={context.id} className="d-flex">
                <div className="d-flex flex-row">
                    <div className="flex-shrink-0 text-muted">
                        <i className="ri-question-answer-line"></i>
                    </div>
                    <div className="flex-grow-1 fs-9 ms-2 d-flex flex-column">
                        {context.message}
                        <small className="text-muted">{new Date(context.created_at).toLocaleDateString()}</small>
                    </div>
                </div>

            </div>)}
        </div>
    }
}

export default TicketItem