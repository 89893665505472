import React, { useState, useEffect, useRef } from 'react'

interface VideoMessagePlayerProps {
  url: string
}

const VideoMessagePlayer: React.FC<VideoMessagePlayerProps> = ({ url }) => {
  const [playing, setPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      setPlaying(!playing)
    }
  }

  const endVideo = () => {
    setPlaying(false)
    if (videoRef.current) {
      videoRef.current.currentTime = 0
    }
  }

  return (
    <div className="video-display-container">
      <video
        controls
        ref={videoRef}
        src={url}
        className="video-display-player"
        onPause={() => setPlaying(false)}
        onEnded={() => setPlaying(false)}
      />
    </div>
  )
}

export default VideoMessagePlayer
