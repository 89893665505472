import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    apiGetAssistant,
    apiCreateAssistant,
    apiUpdateAssistant,
    apiUploadAssistantFile,
    apiUpdateAssistantFile,
} from 'helpers/backend_helper';

export const getAssistant = createAsyncThunk(
    'assistant/getAssistant',
    async ({ storeId }: { storeId: string }, { rejectWithValue }) => {
        try {
            const response = await apiGetAssistant(storeId);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createAssistant = createAsyncThunk(
    'assistant/createAssistant',
    async ({ storeId, params }: { storeId: string; params: any }, { rejectWithValue }) => {
        try {
            const response = await apiCreateAssistant(storeId, params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateAssistant = createAsyncThunk(
    'assistant/updateAssistant',
    async ({ storeId, params }: { storeId: string; params: any }, { rejectWithValue }) => {
        try {
            const response = await apiUpdateAssistant(storeId, params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const uploadAssistantFile = createAsyncThunk(
    'assistant/uploadAssistantFile',
    async ({ storeId, file }: { storeId: string; file: File }, { rejectWithValue }) => {
        try {
            console.log('Uploading file:', file);
            const response = await apiUploadAssistantFile(storeId, file);
            console.log('Upload response:', response);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateAssistantFile = createAsyncThunk(
    'assistant/updateAssistantFile',
    async ({ storeId, fileUid, params }: { storeId: string; fileUid: string; params: any }, { rejectWithValue }) => {
        try {
            const response = await apiUpdateAssistantFile(storeId, fileUid, params);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);