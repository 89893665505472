import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row,
    TabContent, TabPane, FormGroup, Button
} from 'reactstrap';
import classnames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import { createRechargeSession } from '../../../../slices/recharge/thunk';
import { fetchStoreSettings, connectWhatsApp, changePassword, updateStoreSettings } from '../../../../slices/stores/thunk';
import {
    selectProfile,
    selectConnectWhatsAppLoading,
    selectConnectWhatsAppError,
    selectConnectWhatsAppSuccess,
    selectStoreSettings
} from '../../../../selectors';
import Flatpickr from "react-flatpickr";
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

//import images
import progileBg from '../../../../assets/images/profile-bg.jpg';
import avatar1 from '../../../../assets/images/users/avatar-1.jpg';

// Declare the Stripe Pricing Table web component
declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const Settings = () => {
    const [activeTab, setActiveTab] = useState<any>("1");

    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    document.title = "Configuración de la cuenta | Burbuxa";
    interface FormValues {
        attribution_time: string;
    }

    // State variables from Recharge.tsx
    const [amount, setAmount] = useState<number>(0);
    const [showPasswordMessage, setShowPasswordMessage] = useState(false);
    const dispatch: any = useDispatch();
    const { currentStoreId } = useSelector(selectProfile);
    const storeSettings = useSelector(selectStoreSettings);
    // State variables from ConnectWhatsApp.tsx
    const [sessionInfo, setSessionInfo] = useState<any>(null);
    const [sdkResponse, setSdkResponse] = useState<any>(null);

    // Selectors for connectWhatsApp
    const connectWhatsAppLoading = useSelector(selectConnectWhatsAppLoading);
    const connectWhatsAppError = useSelector(selectConnectWhatsAppError);
    const connectWhatsAppSuccess = useSelector(selectConnectWhatsAppSuccess);

    const handleChangePassword = () => {
        dispatch(changePassword());
        setShowPasswordMessage(true);

        // Optionally, hide the message after a few seconds
        setTimeout(() => {
            setShowPasswordMessage(false);
        }, 5000); // Hide after 5 seconds
    };
    // useEffect from ConnectWhatsApp.tsx
    // Initialize Facebook SDK
    useEffect(() => {
        (window as any).fbAsyncInit = function () {
            (window as any).FB.init({
                appId: '1068161864695171',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v20.0'
            });
        };

        // Load Facebook SDK with crossorigin attribute
        (function (d, s, id) {
            var js: any,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            js.crossOrigin = 'anonymous';
            if (fjs && fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs);
            }
        })(document, 'script', 'facebook-jssdk');

        // Event listener for Facebook messages
        const handleMessage = (event: MessageEvent) => {
            if (
                event.origin !== 'https://www.facebook.com' &&
                event.origin !== 'https://web.facebook.com'
            ) {
                return;
            }
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        console.log(
                            'Phone number ID ',
                            phone_number_id,
                            ' WhatsApp business account ID ',
                            waba_id
                        );
                    } else if (data.event === 'CANCEL') {
                        const { current_step } = data.data;
                        console.warn('Cancel at ', current_step);
                    } else if (data.event === 'ERROR') {
                        const { error_message } = data.data;
                        console.error('Error ', error_message);
                    }
                }
                setSessionInfo(data);
            } catch (error) {
                console.log('Non JSON Responses', event.data);
            }
        };

        window.addEventListener('message', handleMessage);

        // Cleanup
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    // Fetch store settings
    useEffect(() => {
        if (currentStoreId) {
            dispatch(fetchStoreSettings(currentStoreId));
        }
    }, [dispatch, currentStoreId]);

    // Dispatch connectWhatsApp when both responses are successful
    useEffect(() => {
        if (
            sessionInfo &&
            sdkResponse &&
            sessionInfo.event === 'FINISH' &&
            sessionInfo.type === 'WA_EMBEDDED_SIGNUP' &&
            sessionInfo.data &&
            sdkResponse.authResponse &&
            sdkResponse.authResponse.code
        ) {
            console.log("Both responses are successful, sending POST request to connect WhatsApp");

            const { phone_number_id, waba_id } = sessionInfo.data;
            const { code } = sdkResponse.authResponse;

            // Dispatch the thunk
            dispatch(connectWhatsApp({ storeId: currentStoreId, phone_number_id, waba_id, code }));
        }
    }, [sessionInfo, sdkResponse, currentStoreId, dispatch]);

    // Functions from Recharge.tsx
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const resultAction = await dispatch(createRechargeSession({ storeId: currentStoreId, amount }));

        if (createRechargeSession.fulfilled.match(resultAction)) {
            const checkoutSessionUrl = resultAction.payload.checkout_session_url;
            window.location.href = checkoutSessionUrl;
        }
    };

    // Functions from ConnectWhatsApp.tsx
    const fbLoginCallback = (response: any) => {
        if (response.authResponse) {
            const code = response.authResponse.code;
            // The code is used in the POST request to connect WhatsApp
        }
        setSdkResponse(response);
    };

    const launchWhatsAppSignup = () => {
        // Launch Facebook login
        (window as any).FB.login(fbLoginCallback, {
            config_id: '528359466215912',
            response_type: 'code',
            override_default_response_type: true,
            extras: {
                setup: {},
                featureType: '',
                sessionInfoVersion: '2',
            }
        });
    };

    // Load Stripe Pricing Table script
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const attributionTimeOptions = [
        { value: '12_HOURS', label: '12 horas' },
        { value: '24_HOURS', label: '24 horas' },
        { value: '48_HOURS', label: '48 horas' },
        { value: '72_HOURS', label: '72 horas' },
        { value: '7_DAYS', label: '7 días' },
        { value: '14_DAYS', label: '14 días' },
        { value: '30_DAYS', label: '30 días' },
    ];

    const SettingsSchema = Yup.object().shape({
        attribution_time: Yup.string().required('Por favor seleccione un tiempo de atribución'),
    });

    return (
        <React.Fragment>
            <div id="fb-root"></div> {/* Include Facebook SDK root div */}
            <div className="page-content">
                <Container fluid>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img">
                            {/* <img src= className="profile-wid-img" alt="" /> */}
                            <div className="overlay-content">
                                <div className="text-end p-3">
                                    {/* <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                                        <Input id="profile-foreground-img-file-input" type="file"
                                            className="profile-foreground-img-file-input" />
                                        <Label htmlFor="profile-foreground-img-file-input"
                                            className="profile-photo-edit btn btn-light">
                                            <i className="ri-image-edit-line align-bottom me-1"></i> Change Cover
                                        </Label>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n5">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img
                                                src={storeSettings?.logo || 'https://burbuxa.sfo3.cdn.digitaloceanspaces.com/isologo.webp'}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                                style={{ objectFit: 'contain', width: '150px', height: '150px' }} // Add this inline style
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input id="profile-img-file-input" type="file"
                                                    className="profile-img-file-input" />
                                                {/* <Label htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label> */}
                                            </div>
                                        </div>
                                        <h5 className="fs-16 mb-1">{storeSettings?.name || 'Store Name'}</h5>
                                        {/* <p className="text-muted mb-0">Lead Designer / Developer</p> */}
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                {/* <CardBody>
                                    <div className="d-flex align-items-center mb-5">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Complete Your Profile</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-primary fs-12"><i
                                                className="ri-edit-box-line align-bottom me-1"></i> Edit</Link>
                                        </div>
                                    </div>
                                    <div className="progress animated-progress custom-progress progress-label">
                                        <div className="progress-bar bg-danger" role="progressbar" style={{ "width": "30%" }}
                                        >
                                            <div className="label">30%</div>
                                        </div>
                                    </div>
                                </CardBody> */}
                            </Card>
                            <Card>
                                {/* <CardBody>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Portfolio</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-primary fs-12"><i
                                                className="ri-add-fill align-bottom me-1"></i> Add</Link>
                                        </div>
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-dark text-light">
                                                <i className="ri-github-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="email" className="form-control" id="gitUsername" placeholder="Username"
                                            defaultValue="@daveadame" />
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-primary">
                                                <i className="ri-global-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="websiteInput"
                                            placeholder="www.example.com" defaultValue="www.velzon.com" />
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-success">
                                                <i className="ri-dribbble-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="dribbleName" placeholder="Username"
                                            defaultValue="@dave_adame" />
                                    </div>
                                    <div className="d-flex">
                                        <div className="avatar-xs d-block flex-shrink-0 me-3">
                                            <span className="avatar-title rounded-circle fs-16 bg-danger">
                                                <i className="ri-pinterest-fill"></i>
                                            </span>
                                        </div>
                                        <Input type="text" className="form-control" id="pinterestName"
                                            placeholder="Username" defaultValue="Advance Dave" />
                                    </div>
                                </CardBody> */}
                            </Card>
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        {/* <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}>
                                                <i className="fas fa-home"></i>
                                                Personal Details
                                            </NavLink>
                                        </NavItem> */}
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}
                                                type="button">
                                                <i className="far fa-user"></i>
                                                Recargar Balance
                                            </NavLink>
                                        </NavItem>
                                        <NavItem >
                                            <NavLink
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}
                                                type="button">
                                                <i className="far fa-envelope"></i>
                                                Cambiar contraseña
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "3" })}
                                                onClick={() => {
                                                    tabChange("3");
                                                }}
                                                type="button">
                                                <i className="far fa-envelope"></i>
                                                {storeSettings?.messaging_provider_configured ? 'Reconectar WhatsApp' : 'Conectar WhatsApp'}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "4" })}
                                                onClick={() => {
                                                    tabChange("4");
                                                }}
                                                type="button">
                                                <i className="far fa-credit-card"></i>
                                                Planes y facturación
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "5" })}
                                                onClick={() => {
                                                    tabChange("5");
                                                }}
                                                type="button">
                                                <i className="far fa-cog"></i>
                                                Configuración
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        {/* <TabPane tabId="4">
                                            <Form>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstnameInput" className="form-label">First
                                                                Name</Label>
                                                            <Input type="text" className="form-control" id="firstnameInput"
                                                                placeholder="Enter your firstname" defaultValue="Dave" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="lastnameInput" className="form-label">Last
                                                                Name</Label>
                                                            <Input type="text" className="form-control" id="lastnameInput"
                                                                placeholder="Enter your lastname" defaultValue="Adame" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="phonenumberInput" className="form-label">Phone
                                                                Number</Label>
                                                            <Input type="text" className="form-control"
                                                                id="phonenumberInput"
                                                                placeholder="Enter your phone number"
                                                                defaultValue="+(1) 987 6543" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">Email
                                                                Address</Label>
                                                            <Input type="email" className="form-control" id="emailInput"
                                                                placeholder="Enter your email"
                                                                defaultValue="daveadame@velzon.com" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="JoiningdatInput" className="form-label">Joining
                                                                Date</Label>
                                                            <Flatpickr
                                                                className="form-control"
                                                                options={{
                                                                    dateFormat: "d M, Y"
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="skillsInput" className="form-label">Skills</Label>
                                                            <select className="form-select mb-3">
                                                                <option >Select your Skill </option>
                                                                <option value="Choices1">CSS</option>
                                                                <option value="Choices2">HTML</option>
                                                                <option value="Choices3">PYTHON</option>
                                                                <option value="Choices4">JAVA</option>
                                                                <option value="Choices5">ASP.NET</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="designationInput"
                                                                className="form-label">Designation</Label>
                                                            <Input type="text" className="form-control"
                                                                id="designationInput" placeholder="Designation"
                                                                defaultValue="Lead Designer / Developer" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="websiteInput1"
                                                                className="form-label">Website</Label>
                                                            <Input type="text" className="form-control" id="websiteInput1"
                                                                placeholder="www.example.com" defaultValue="www.velzon.com" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="cityInput" className="form-label">City</Label>
                                                            <Input type="text" className="form-control" id="cityInput"
                                                                placeholder="City" defaultValue="California" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="countryInput" className="form-label">Country</Label>
                                                            <Input type="text" className="form-control" id="countryInput"
                                                                placeholder="Country" defaultValue="United States" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="zipcodeInput" className="form-label">Zip
                                                                Code</Label>
                                                            <Input type="text" className="form-control" minLength={5}
                                                                maxLength={6} id="zipcodeInput"
                                                                placeholder="Enter zipcode" defaultValue="90011" />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="mb-3 pb-2">
                                                            <Label htmlFor="exampleFormControlTextarea"
                                                                className="form-label">Description</Label>
                                                            <textarea className="form-control"
                                                                id="exampleFormControlTextarea"
                                                                rows={3} defaultValue="Hi I'm Anna Adame, It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is European languages are members of the same family."></textarea>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="button"
                                                                className="btn btn-secondary">Updates</button>
                                                            <button type="button"
                                                                className="btn btn-soft-danger">Cancel</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane> */}

                                        <TabPane tabId="2">
                                            <Form>
                                                <Row className="g-2">
                                                    <Col lg={12}>
                                                        <div className="p-4">
                                                            <button type="button" className="btn btn-primary" onClick={handleChangePassword}>
                                                                Cambiar contraseña
                                                            </button>
                                                            {showPasswordMessage && (
                                                                <div className="mt-3 alert alert-success">
                                                                    El correo de restablecimiento de contraseña ha sido enviado a tu correo electrónico.
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>

                                        <TabPane tabId="3">
                                            {/* Content from ConnectWhatsApp.tsx */}
                                            <div className="p-4">
                                                <button
                                                    onClick={launchWhatsAppSignup}
                                                    className="btn btn-primary"
                                                >
                                                    {storeSettings?.messaging_provider_configured ? 'Reconectar WhatsApp' : 'Conectar WhatsApp'}
                                                </button>
                                                <br></br>
                                                {/* Display loading, success, error messages */}
                                                {connectWhatsAppLoading && <p>Connecting WhatsApp...</p>}
                                                {connectWhatsAppError && <p className="text-danger">Error: {connectWhatsAppError}</p>}
                                                {connectWhatsAppSuccess && <p className="text-success">WhatsApp conectado correctamente!</p>}
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="1">
                                            {/* Content from Recharge.tsx */}
                                            {!storeSettings?.subscription_plan ? (
                                                <div className="p-4">
                                                    <p>Debes suscribirte primero para poder recargar tu balance.</p>
                                                </div>
                                            ) : (
                                                <Row>
                                                    <Col xs={12}>
                                                        <div className="mb-3">
                                                            <h5>Balance actual: US$ {storeSettings?.balance?.toFixed(2) ?? 'Obteniendo balance...'}</h5>

                                                        </div>
                                                        <Form onSubmit={handleSubmit}>
                                                            <FormGroup>
                                                                <Label for="amount">Cantidad</Label>

                                                                <Input
                                                                    type="number"
                                                                    name="amount"
                                                                    id="amount"
                                                                    value={amount || ''}
                                                                    onChange={(e) => setAmount(parseFloat(e.target.value))}
                                                                    placeholder="Ingresa la cantidad a recargar o selecciona debajo una de las opciones frecuentes"
                                                                    min="25"
                                                                    step="5"
                                                                    required
                                                                />
                                                                <div className="mt-3 d-flex gap-2 flex-wrap">
                                                                    {[25, 50, 100, 250, 500, 1000, 2500, 5000, 10000].map((value) => (
                                                                        <Button
                                                                            key={value}
                                                                            type="button"
                                                                            color={amount === value ? "dark" : "light"}
                                                                            onClick={() => setAmount(value)}
                                                                            className="px-3 py-1"
                                                                        >
                                                                            US$ {value.toLocaleString()}
                                                                        </Button>
                                                                    ))}
                                                                </div>
                                                            </FormGroup>
                                                            <span className="text-muted fs-12">El valor de cada crédito es de US$ 1. Por ejemplo: en plan Business 100 créditos cubren 2000 mensajes; en plan Advance 100 créditos cubren 3333 mensajes y en plan Pro Player 100 créditos cubren 5000 mensajes. Recuerda que el costo de Meta lo cobra Meta por separado y no se descuenta de este balance.</span> <br /> <br />
                                                            <Button type="submit" color="primary">Recargar</Button>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                            )}
                                        </TabPane>

                                        <TabPane tabId="4">
                                            {/* Stripe Pricing Table Content */}
                                            {/* prctbl_1Q7IPvAMS38OPBxFt42gO4MJ
                                            pk_live_51LcFm5AMS38OPBxF2Y992Mv88FeNKN6SmiWYUTYeYk7y34E3fFdCW12xvDGATvkc4ZJ1HAhBUBJLWVpCpwi3ct9800dq6LJ32B */}

                                            <div className="p-4">
                                                {storeSettings?.subscription_plan ? (
                                                    <a href={storeSettings.stripe_customer_portal_link} className="btn btn-primary">
                                                        Ver facturas y manejar suscripción
                                                    </a>
                                                ) : storeSettings ? (
                                                    <stripe-pricing-table
                                                        pricing-table-id="prctbl_1Q7IPvAMS38OPBxFt42gO4MJ"
                                                        publishable-key="pk_live_51LcFm5AMS38OPBxF2Y992Mv88FeNKN6SmiWYUTYeYk7y34E3fFdCW12xvDGATvkc4ZJ1HAhBUBJLWVpCpwi3ct9800dq6LJ32B"
                                                        customer-session-client-secret={storeSettings.stripe_pricing_table_customer_session}
                                                    >
                                                    </stripe-pricing-table>
                                                ) : (
                                                    <p>Cargando configuración...</p>
                                                )}
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="5">
                                            <Formik<FormValues>
                                                initialValues={{
                                                    attribution_time: storeSettings?.attribution_time || '',
                                                }}
                                                enableReinitialize={true}
                                                validationSchema={SettingsSchema}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    dispatch(updateStoreSettings({ storeId: currentStoreId, settings: values }))
                                                        .then(() => {
                                                            setSubmitting(false);
                                                        })
                                                        .catch(() => {
                                                            setSubmitting(false);
                                                        });
                                                }}
                                            >
                                                {({ values, errors, touched, isSubmitting, handleSubmit }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <FormGroup>
                                                            <Label for="attribution_time">Tiempo de atribución por defecto: es el tiempo máximo entre el mensaje y la compra para que se considere una conversión</Label>
                                                            <Input
                                                                type="select"
                                                                name="attribution_time"
                                                                id="attribution_time"
                                                                tag={Field}
                                                                as="select"
                                                                invalid={touched.attribution_time && !!errors.attribution_time}
                                                            >
                                                                <option value="">-- Seleccione el tiempo de atribución --</option>
                                                                {attributionTimeOptions.map(option => (
                                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                                ))}
                                                            </Input>
                                                            {touched.attribution_time && errors.attribution_time ? (
                                                                <div className="invalid-feedback d-block">{errors.attribution_time}</div>
                                                            ) : null}
                                                        </FormGroup>
                                                        <Button type="submit" color="primary" disabled={isSubmitting}>
                                                            Guardar
                                                        </Button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Settings;