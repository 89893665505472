import { createSlice } from "@reduxjs/toolkit";
import { getTickets, updateTicket } from "./thunk";
import { Ticket } from "types/Dashboard";

export const initialState = {
    ticketItems: [] as Ticket[],
    page: 1,
    limit: 10,
    total: 0,
    status: 'loaded' as ('loading' | 'loaded')
};

const TicketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTickets.pending, (state: any) => {
            state.status = 'loading'
            state.error = null
            state.ticketItems = []
        })
        builder.addCase(getTickets.fulfilled, (state: any, action: any) => {
            state.ticketItems = action.payload.items
            state.total = action.payload.total
            state.limit = action.payload.limit
            state.skip = action.payload.skip
            state.status = 'loaded'
        })
        builder.addCase(getTickets.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.status = 'loaded'
        })
        builder.addCase(updateTicket.fulfilled, (state: any, action: any) => {
            state.ticketItems = state.ticketItems.map((ticket: Ticket) => ticket.id === action.payload.id ? action.payload : ticket);
        })
    }
});

export default TicketsSlice.reducer;