import { createSlice } from "@reduxjs/toolkit";
import {
  getConversations,
  getConversationById,
  updateConversation,
  sendMessage,
  getConversationMessages,
  assignConversation,
  receiveIncomingMessage,
  findLastConversationByPhone,
} from "./thunk";

interface Conversation {
  id: number;
  store_id: number;
  phone_number: string;
  customer_whatsapp_name?: string;
  avatar_color?: string;
  status?: string;
  assistant_enabled?: boolean;
  // Other fields...
}

interface ChatState {
  status: "idle" | "loading" | "succeeded" | "failed";
  conversations: Conversation[];
  conversation: Conversation | null;
  messages: any[];
  error: string | null;
  totalConversations: number;
  totalMessages: number;
  page: number;
  perPage: number;
}

const initialState: ChatState = {
  status: "idle",
  conversations: [],
  conversation: null,
  messages: [],
  error: null,
  totalConversations: 0,
  totalMessages: 0,
  page: 1,
  perPage: 10,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Conversations
    builder.addCase(getConversations.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getConversations.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.conversations = action.payload.conversations;
      state.totalConversations = action.payload.total;
    });
    builder.addCase(getConversations.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Get Conversation by ID
    builder.addCase(getConversationById.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getConversationById.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.conversation = action.payload;
    });
    builder.addCase(getConversationById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Update Conversation
    builder.addCase(updateConversation.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateConversation.fulfilled, (state, action) => {
      state.status = "succeeded";
      // Update conversation in the list
      const index = state.conversations.findIndex(
        (conv: Conversation) => conv.id === action.payload.id
      );
      if (index !== -1) {
        state.conversations[index] = action.payload;
      }
      if (state.conversation && state.conversation.id === action.payload.id) {
        state.conversation = action.payload;
      }
    });
    builder.addCase(updateConversation.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Send Message
    builder.addCase(sendMessage.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.messages.push(action.payload);
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Get Conversation Messages
    builder.addCase(getConversationMessages.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getConversationMessages.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.messages = action.payload.messages;
      state.totalMessages = action.payload.total;
    });
    builder.addCase(getConversationMessages.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Assign Conversation
    builder.addCase(assignConversation.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(assignConversation.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Receive Incoming Message
    builder.addCase(receiveIncomingMessage.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(receiveIncomingMessage.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.messages.push(action.payload);
    });
    builder.addCase(receiveIncomingMessage.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });
    builder.addCase(findLastConversationByPhone.pending, (state) => {
      state.status = "loading";
      state.conversation = null;
    });
    builder.addCase(findLastConversationByPhone.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.conversation = action.payload;
    });
  },
});

export const { setPage, setPerPage } = chatSlice.actions;

export default chatSlice.reducer;
