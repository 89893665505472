import { createSlice } from "@reduxjs/toolkit";
import { getTickets } from "slices/tickets/thunk";
import { BasicUser } from "types/Dashboard";
import { getUsers } from "./thunk";

export const initialState = {
    users: [] as BasicUser[],
    status: 'loaded' as ('loading' | 'loaded')
};

const UserSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsers.pending, (state: any) => {
            state.status = 'loading'
            state.error = null
            state.users = []
        })
        builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
            state.users = action.payload.items
            state.status = 'loaded'
        })
        builder.addCase(getUsers.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
            state.status = 'loaded'
        })
    }
});

export default UserSlice.reducer;