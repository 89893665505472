import React, { useState, useEffect, useRef } from 'react'

interface ImageMessageProps {
  url: string
}

const ImageMessageDisplay: React.FC<ImageMessageProps> = ({ url }) => {
  return (
    <div className="image-display-container">
      <img src={url} className="image-display-img" />
    </div>
  )
}

export default ImageMessageDisplay
