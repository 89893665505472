import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCreateRechargeCheckoutSession } from "../../helpers/backend_helper";
import { toast } from "react-toastify";

export const createRechargeSession = createAsyncThunk(
    "recharge/createRechargeSession",
    async (
        { storeId, amount }: { storeId: string; amount: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await apiCreateRechargeCheckoutSession(storeId, amount);
            return response;
        } catch (error: any) {
            if (error.response) {
                const errorMessage = error.response.data.detail || "An error occurred";
                toast(errorMessage, {
                    position: "top-right",
                    hideProgressBar: false,
                    className: "bg-danger text-white",
                    progress: undefined,
                });
                return rejectWithValue(error.response.data);
            } else {
                toast("Network Error", {
                    position: "top-right",
                    hideProgressBar: false,
                    className: "bg-danger text-white",
                    progress: undefined,
                });
                return rejectWithValue("Network Error");
            }
        }
    }
);