import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectProfile } from "selectors";
import { setCurrentStore } from "../../slices/auth/profile/thunk";
import { useQueryParam, StringParam } from "use-query-params";


const useCurrentStore = () => {
    const { currentStoreId, user } = useSelector(selectProfile)
    const [queryStore, setQueryStore] = useQueryParam('store', StringParam)
    const dispatch: any = useDispatch();

    useEffect(() => {
        if (queryStore && !currentStoreId && user && user.store_permissions) {
            const store = user.store_permissions.find((permission: any) => permission.store.uid == queryStore);
            if (store) {
                dispatch(setCurrentStore(queryStore))
            }
        } else if (currentStoreId && currentStoreId != queryStore) {
            setQueryStore(currentStoreId)
        } else if (!currentStoreId && !queryStore && user && user.store_permissions) {
            const currentStore = user.store_permissions[0].store
            dispatch(setCurrentStore(currentStore.uid))
        }
    }, [currentStoreId, dispatch, user, queryStore]);

    return currentStoreId;
}

export default useCurrentStore;