import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
    deleteTicket as deleteTicketApi,
    apiGetTickets,
    apiPostTicket,
    apiPatchTicket
} from "../../helpers/backend_helper";

export const getTickets = createAsyncThunk("tickets/getTicketsList", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetTickets(storeId, params);
        return response;
    } catch (error) {
        return error;
    }
});

export const createTicket = createAsyncThunk("tickets/createTicket", async ({ storeId, ...ticket }: any) => {
    try {
        const response = apiPostTicket(storeId, ticket);
        const data = await response;
        toast(`Ticket creado`, {
            position: "top-right",
            hideProgressBar: false,
            className: 'bg-success text-white',
            progress: undefined,
            toastId: ""
        })
        return data;
    } catch (error) {
        return error;
    }
});

export const updateTicket = createAsyncThunk("tickets/updateTicket", async ({ storeId, ticketId, data }: any) => {
    try {
        const response = apiPatchTicket(storeId, ticketId, data);
        const updatedInfo = await response;
        toast.success("Ticket actualizado correctamente", { autoClose: 3000 });
        return updatedInfo;
    } catch (error) {
        toast.error("Ticket actualización fallida", { autoClose: 3000 });
        return error;
    }
});

export const deleteTicket = createAsyncThunk("tickets/deleteTicket", async (ticket: any) => {
    try {
        const response = deleteTicketApi(ticket);
        toast.success("Ticket Delete Successfully", { autoClose: 3000 });
        return { ticket, ...response };
    } catch (error) {
        toast.error("Ticket Delete Failed", { autoClose: 3000 });
        return error;
    }
});