import { createSlice } from "@reduxjs/toolkit";
import {
    getCountries,
    getPaymentMethods,
    getProductCategories,
    connectWhatsApp,
    fetchStoreSettings,
    updateStoreSettings
} from './thunk';

export const initialState: any = {
    paymentMethods: [],
    countries: [],
    productCategories: [],
    settings: null, // Add a new state property for store settings
    loading: false,
    error: null,
};

const StoreSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Existing cases...

        // Handle fetchStoreSettings
        builder
            .addCase(fetchStoreSettings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStoreSettings.fulfilled, (state, action) => {
                state.loading = false;
                state.settings = action.payload;
            })
            .addCase(fetchStoreSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch store settings';
            })
            .addCase(updateStoreSettings.pending, (state) => {
                state.updatingSettings = true;
                state.updateSettingsError = null;
            })
            .addCase(updateStoreSettings.fulfilled, (state, action) => {
                state.updatingSettings = false;
                state.settings = { ...state.settings, ...action.payload };
            })
            .addCase(updateStoreSettings.rejected, (state, action) => {
                state.updatingSettings = false;
                state.updateSettingsError = action.payload || 'Failed to update store settings';
            });

        // Existing cases for other thunks...
        builder.addCase(getPaymentMethods.fulfilled, (state: any, action: any) => {
            state.paymentMethods = action.payload;
        })

        builder.addCase(getPaymentMethods.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        })

        builder.addCase(getCountries.fulfilled, (state: any, action: any) => {
            state.countries = action.payload;
        })

        builder.addCase(getCountries.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        })

        builder.addCase(getProductCategories.fulfilled, (state: any, action: any) => {
            state.productCategories = action.payload;
        })

        builder.addCase(getProductCategories.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        })

        // Handle connectWhatsApp
        builder
            .addCase(connectWhatsApp.pending, (state) => {
                state.connectWhatsAppLoading = true;
                state.connectWhatsAppError = null;
                state.connectWhatsAppSuccess = null;
            })
            .addCase(connectWhatsApp.fulfilled, (state, action) => {
                state.connectWhatsAppLoading = false;
                state.connectWhatsAppSuccess = action.payload;
            })
            .addCase(connectWhatsApp.rejected, (state, action) => {
                state.connectWhatsAppLoading = false;
                state.connectWhatsAppError = action.payload || 'Failed to connect WhatsApp';
            });

    }
});

export default StoreSlice.reducer;