import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from 'Components/Common/BreadCrumb';
import AssistantForm from 'Components/Assistant/AssistantForm';
import { useDispatch, useSelector } from 'react-redux';
import { getAssistant } from 'slices/assistant/thunk';
import { selectAssistant, selectProfile } from 'selectors';
import useCurrentStore from 'Components/Hooks/CurrentStore';

const AssistantPage = () => {
    useCurrentStore()
    const dispatch: any = useDispatch();
    const { currentStoreId } = useSelector(selectProfile);
    const { assistant, status } = useSelector(selectAssistant);

    useEffect(() => {
        if (currentStoreId) {
            dispatch(getAssistant({ storeId: currentStoreId }));
        }
    }, [currentStoreId]);

    document.title = 'Administrar Burbujita | Burbuxa';

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Administrar Burbujita" pageTitle="Burbujita" />
                    <Row>
                        <Col xs={12}>
                            <AssistantForm assistant={assistant} loading={status === 'loading'} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AssistantPage;