import { createSlice } from "@reduxjs/toolkit";
import { ICampaign, IReport } from "types/Dashboard";
import { getCampaigns, getOrderList, getReport, getStoreReport, getCampaignDistribution, getCampaignById, createCampaignBudget, updateCampaign, createCampaign, pauseCampaign, resumeCampaign, deleteCampaign } from './thunk';




export const initialState: any = {
    status: 'loaded' as ('loading' | 'loaded'),
    transationList: [],
    orderList: [],
    budget: null,
    campaign: null as (ICampaign | null),
    campaignItems: [] as ICampaign[],
    campaignReport: null as (IReport | null),
    campaignDistribution: null as (IReport | null),
    limit: 0,
    total: 0,
    skip: 0,
    redirToList: false,
};

const CampaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReport.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(getReport.fulfilled, (state: any, action: any) => {
            const data = action.payload
            state.status = 'loaded'
            state.campaignReport = {
                roi: data.roi,
                abandonedCartVolume: data.abandoned_cart_volume,
                abandonedCartCount: data.abandoned_cart_count,
                totalRecoveredCarts: data.total_recovered_carts,
                totalMessagesSent: data.total_messages_sent,
                totalRecoveredVolume: data.total_recovered_volume,
                totalConversionRate: data.total_conversion_rate,
                totalCost: data.total_cost,
                totalClientsReached: data.total_clients_reached,
                totalInvestment: data.total_investment,
                campaign: data.campaign,
                group: data.group,
                groupType: data.group_type,
                distribution: data.distribution,
                totalOrders: data.total_orders,
                totalInvestmentStore: data.total_investment_store,
                totalRecoveredVolumeStore: data.total_recovered_volume_store,
            }
        })
        builder.addCase(getStoreReport.fulfilled, (state: any, action: any) => {
            const data = action.payload;
            state.campaignReport = {
                roi: data.roi,
                abandonedCartVolume: data.abandoned_cart_volume,
                abandonedCartCount: data.abandoned_cart_count,
                totalRecoveredCarts: data.total_recovered_carts,
                totalMessagesSent: data.total_messages_sent,
                totalRecoveredVolume: data.total_recovered_volume,
                totalRecoveredVolumeStore: data.total_recovered_volume_store,
                totalConversionRate: data.total_conversion_rate,
                totalCost: data.total_cost,
                totalClientsReached: data.total_clients_reached,
                totalInvestment: data.total_investment,
                totalInvestmentStore: data.total_investment_store,
                group: data.group,
                groupType: data.group_type,
                campaign: data.campaign,
                totalOrders: data.total_orders,
            }
        })
        builder.addCase(getCampaignById.fulfilled, (state: any, action: any) => {
            state.campaign = action.payload
        })
        builder.addCase(createCampaignBudget.pending, (state: any, action: any) => {
            state.budget = null
            state.status = 'loading'
        })
        builder.addCase(createCampaignBudget.fulfilled, (state: any, action: any) => {
            state.budget = action.payload
            state.status = 'loaded'
        })
        builder.addCase(createCampaignBudget.rejected, (state: any, action: any) => {
            state.budget = null
            state.status = 'loaded'
        })
        builder.addCase(updateCampaign.pending, (state: any, action: any) => {
            state.budget = null
            state.status = 'loading'
        })
        builder.addCase(updateCampaign.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
            state.redirToList = true
        })
        builder.addCase(createCampaign.pending, (state: any, action: any) => {
            state.status = 'loading'
        })
        builder.addCase(createCampaign.fulfilled, (state: any, action: any) => {
            state.status = 'loaded'
            state.redirToList = true
        })
        builder.addCase(getCampaignById.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null
        })
        builder.addCase(getCampaigns.pending, (state: any, action: any) => {
            state.status = 'loading'
            state.campaignItems = null
        })
        builder.addCase(deleteCampaign.fulfilled, (state: any, action: any) => {
            const { status, id } = action.payload
            state.status = 'loaded'
            console.log(status, id)

            const index = state.campaignItems.findIndex((campaign: any) => campaign.id == id)
            state.campaignItems.splice(index, 1)
        })
        builder.addCase(pauseCampaign.fulfilled, (state: any, action: any) => {
            const { status, id } = action.payload
            state.status = 'loaded'
            const match = state.campaignItems.find((campaign: any) => campaign.id == id)
            if (match && match.status != status) {
                match.status = status
            }
        })
        builder.addCase(resumeCampaign.fulfilled, (state: any, action: any) => {
            const { status, id } = action.payload
            state.status = 'loaded'

            const match = state.campaignItems.find((campaign: any) => campaign.id == id)
            if (match.status != status) {
                match.status = status
            }
        })
        builder.addCase(getCampaigns.fulfilled, (state: any, action: any) => {
            state.campaignItems = action.payload?.items
            state.total = action.payload?.total
            state.limit = action.payload?.limit
            state.skip = action.payload?.skip
            state.redirToList = false
            state.status = 'loaded'
        })
        builder.addCase(getCampaigns.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null
        })
        builder.addCase(getCampaignDistribution.fulfilled, (state: any, action: any) => {
            state.campaignDistribution = action.payload
        })
        builder.addCase(getCampaignDistribution.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null
            state.campaignDistribution = null
        })
    }
});

export default CampaignSlice.reducer;
