import { Link } from "react-router-dom";
import { Card, CardHeader, DropdownToggle, UncontrolledCollapse, UncontrolledDropdown } from "reactstrap";

export interface BoardItemData {
    id: string;
    title: string;
    description: string;
    columnIndex: number;
    content: React.ReactNode;
    action: React.ReactNode;
    dropdown?: React.ReactNode;
    header?: React.ReactNode;
}

const BoardItem = ({ item, onClick }: { item: BoardItemData, onClick?: () => void }) => {
    return <Card
        className="m-1"
        draggable
        onClick={onClick}
        onDragStart={(e) => {
            e.dataTransfer.setData("text/plain", JSON.stringify({
                id: item.id,
                columnIndex: item.columnIndex,
            }));
        }}
    >
        <CardHeader className="shadow-sm text-start p-1">
            <div className="d-flex flex-row justify-content-between">
                {item.header}
                {item.dropdown ? <UncontrolledDropdown>
                    <DropdownToggle size="sm" className="p-0" color="light">
                        <i className="ri-more-2-fill"></i>
                    </DropdownToggle>
                    {item.dropdown}
                </UncontrolledDropdown> : undefined}
            </div>
            <Link to='#' id={`boardItem${item.id}`} className="text-decoration-none">
                <p className="fw-bold fs-10 text-muted m-0">{item.title}</p>
                <p className="text-muted fs-10 p-1 m-0">{item.description}</p>
            </Link>
            <UncontrolledCollapse toggler={`boardItem${item.id}`} defaultOpen={false}>
                <div className="fs-10">
                    {item.content}
                </div>
            </UncontrolledCollapse>
            {item.action ? <div className="text-center">
                {item.action}
            </div> : undefined}
        </CardHeader>
    </Card>
}

export default BoardItem