import { Col, Container, Row } from "reactstrap";
import BoardColumn, { BoardColumnData } from "./BoardColumn";
import BoardItem, { BoardItemData } from "./BoardItem";


interface BoardProps {
    columns: BoardColumnData[];
    items: BoardItemData[];
    movableItem?: boolean;
    onItemMoved: (item: BoardItemData, columnIndex: number) => void;
    onClick?: (item: BoardItemData) => void;

}

const Board = ({ columns, items, movableItem = true, onItemMoved, onClick }: BoardProps) => {

    return <Container fluid>
        <Row>
            <Col sm={12}>
                <div className="hstack gap-2 overflow-scroll-x">
                    {columns.map((column, columnIndex) => (
                        <div
                            style={{
                                ...column.style,
                                flex: 1,
                                maxHeight: '80vh',
                                overflowY: 'auto'
                            }}
                            key={columnIndex}
                            className={`vstack shadow-sm rounded ${column.className}`}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                                e.preventDefault();
                                const droppedItem = JSON.parse(e.dataTransfer.getData("text/plain"));
                                if (droppedItem.columnIndex !== columnIndex && movableItem) {
                                    onItemMoved(droppedItem, columnIndex);
                                }
                                e.currentTarget.style.backgroundColor = "";
                            }}
                            onDragEnter={(e) => e.currentTarget.style.backgroundColor = "lightblue"}
                            onDragLeave={(e) => e.currentTarget.style.backgroundColor = ""}
                        >
                            <BoardColumn column={column} />
                            {
                                items.filter((item: BoardItemData) => item.columnIndex === columnIndex)
                                    .map(
                                        (item: BoardItemData, itemIndex: number) => (
                                            <BoardItem item={item} key={itemIndex} onClick={() => onClick?.(item)} />
                                        )
                                    )
                            }
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    </Container >
}

export default Board;   