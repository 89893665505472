import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    apiGetCountries,
    apiGetPaymentMethods,
    apiGetCampaignProductCategories,
    apiGetStoreSettings,
    apiPostConnectWhatsApp,
    apiPostChangePassword,
    apiUpdateStoreSettings
} from "../../helpers/backend_helper";

export const getPaymentMethods = createAsyncThunk("stores/getPaymentMethods", async ({ storeId }: any) => {
    try {
        const response = apiGetPaymentMethods(storeId);
        return response;
    } catch (error) {
        return error;
    }
});

export const getCountries = createAsyncThunk("stores/countries", async () => {
    try {
        const response = apiGetCountries();
        return response;
    } catch (error) {
        return error;
    }
});

export const updateStoreSettings = createAsyncThunk(
    'store/updateStoreSettings',
    async ({ storeId, settings }: any, { rejectWithValue }) => {
        try {
            const response = await apiUpdateStoreSettings(storeId, settings);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const getProductCategories = createAsyncThunk("store/getCampaignProductCategories", async ({
    storeId
}: any) => {
    try {
        const response = apiGetCampaignProductCategories(storeId);
        return response;
    } catch (error) {
        return error
    }
})

export const fetchStoreSettings = createAsyncThunk(
    'store/fetchStoreSettings',
    async (storeId: string, { rejectWithValue }) => {
        try {
            const response = await apiGetStoreSettings(storeId);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const connectWhatsApp = createAsyncThunk(
    'store/connectWhatsApp',
    async (
        { storeId, phone_number_id, waba_id, code }: any,
        { rejectWithValue }
    ) => {
        try {
            const payload = {
                phone_number_id,
                waba_id,
                code,
            };
            const response = await apiPostConnectWhatsApp(storeId, payload);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const changePassword = createAsyncThunk(
    'stores/changePassword',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiPostChangePassword();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);