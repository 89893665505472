import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: "",
  success: "",
  user: {},
  currentStoreId: null,
  currentStoreName: null,
  currentStoreCurrency: null,
  enabledFeatures: []
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    setCurrentStore(state: any, action) {
      state.currentStoreId = action.payload.uid
      state.currentStoreName = action.payload.name
      state.currentStoreCurrency = action.payload.currency
      state.enabledFeatures = action.payload.enabled_features
    },
    profileSuccess(state: any, action: any) {
      state.user = action.payload
      /*
      if (state.user?.store_permissions) {
        state.currentStoreId = state.user.store_permissions[0].store.uid
        state.currentStoreName = state.user.store_permissions[0].store.name
        state.enabledFeatures = state.user.store_permissions[0].store.enabled_features
        state.currentStoreCurrency = state.user.store_permissions[0].store.currency
      }
      */
    },
    profileError(state, action) {
      state.error = action.payload
    },
    editProfileChange(state) {
      state = { ...state };
    },
    resetProfileFlagChange(state: any) {
      state.success = null
    }
  },
});

export const {
  profileSuccess,
  profileError,
  editProfileChange,
  resetProfileFlagChange,
  setCurrentStore,
} = ProfileSlice.actions

export default ProfileSlice.reducer;