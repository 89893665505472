import { Card, CardHeader } from "reactstrap";

export interface BoardColumnData {
    title: string;
    description?: string;
    className?: string;
    style?: React.CSSProperties;
}

const BoardColumn = ({ column }: { column: BoardColumnData }) => {
    return <Card className="m-0">
        <CardHeader className={`${column.className} border-0`}>
            <h5 className="card-title text-uppercase fw-semibold fs-14">
                {column.title}
            </h5>
            {column.description !== undefined && <p className="text-muted mb-0">
                {column.description}
            </p>}
        </CardHeader>
    </Card>
}

export default BoardColumn;