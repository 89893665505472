import { createSlice } from "@reduxjs/toolkit";
import { getCrmContacts } from './thunk';
import { GenericContact } from "types/Dashboard";

export const initialState: any = {
  contacts: [] as GenericContact[],
  companies: [],
  status: 'loaded' as ('loading' | 'loaded' | 'error'),
  deals: [],
  leads: [],
  error: {},
  limit: 0,
  total: 0,
  skip: 0,
};

const crmSlice = createSlice({
  name: "crm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCrmContacts.pending, (state: any, action: any) => {
      state.status = 'loading'
    })

    builder.addCase(getCrmContacts.fulfilled, (state: any, action: any) => {
      state.status = 'loaded'
      state.contacts = action.payload.items
      state.limit = action.payload.limit
      state.total = action.payload.total
      state.skip = action.payload.skip
    })
  },
});

export default crmSlice.reducer;