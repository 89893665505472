import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
    Button,
    Spinner,
    Table,
    FormText,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createAssistant, updateAssistant, uploadAssistantFile, updateAssistantFile } from 'slices/assistant/thunk';
import { selectAssistant, selectProfile } from 'selectors';
import * as Yup from 'yup';

const AssistantForm = ({ assistant, loading }: any) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const { currentStoreId } = useSelector(selectProfile);
    const fileInputRef = useRef<any>(null);
    interface AssistantFormValues {
        additional_instructions?: string;
        is_enabled?: boolean;
        description?: string;
        tools: { [key: string]: boolean };
    }

    const textPlaceholder = `- Utiliza un tono amable y cercano con expresiones de México.
- Siempre pregunta por el correo del usuario antes de responder.
- El horario de apertura para el día domingo es de 10:00 a 18:00.
- Nuestro nombre legal es Ejemplo LLC.
- Si te consultan a donde hacer las transferencias de dinero diles que el CBU es XXXXXXXXXXXXXXXXXX en el Citibank.
- Si quieren hablar con un humano diles que nuestro número de teléfono es wa.me/1234567890.`;

    const descriptionPlaceholder = `Sport Wear es una marca de ropa deportiva que ofrece productos para todo tipo de actividades físicas. Vende productos para correr, entrenar y hacer deporte. Además tenemos un sector multimarca de ropa casual y deportiva con productos Nike y Adidas.`;

    const [file, setFile] = useState<File | null>(null);
    const [fileError, setFileError] = useState<string | null>(null);

    const validationSchema = Yup.object().shape({
        additional_instructions: Yup.string(),
        is_enabled: Yup.boolean(),
        tools: Yup.object().shape({
            retrieve_store_orders_history: Yup.boolean(),
            retrieve_store_payment_methods: Yup.boolean(),
            retrieve_store_products: Yup.boolean(),
        }),
        description: Yup.string(),
    });

    const formik = useFormik<AssistantFormValues>({
        enableReinitialize: true,
        initialValues: {
            additional_instructions: assistant?.additional_instructions || '',
            is_enabled: assistant?.is_enabled ?? true,
            description: assistant?.description || '',
            tools: {
                retrieve_store_orders_history: assistant?.tools?.retrieve_store_orders_history ?? true,
                retrieve_store_payment_methods: assistant?.tools?.retrieve_store_payment_methods ?? true,
                retrieve_store_products: assistant?.tools?.retrieve_store_products ?? true,
            },
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (assistant?.name !== null) {
                // Update assistant
                dispatch(updateAssistant({ storeId: currentStoreId, params: values }));
            } else {
                // Create assistant
                dispatch(createAssistant({ storeId: currentStoreId, params: values }));
            }
        },
    });

    const handleFileUpload = () => {
        if (file) {
            dispatch(uploadAssistantFile({ storeId: currentStoreId, file }));
            setFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleUpdateFile = (fileUid: string, params: any) => {
        dispatch(updateAssistantFile({ storeId: currentStoreId, fileUid, params }));
    };

    // Ensure files is always an array
    const files = Array.isArray(assistant?.files) ? assistant.files : [];

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col xxl={12}>
                        <Card>
                            <CardBody>
                                <p className="text-muted">
                                    Desde aquí podrás configurar el asistente y subir archivos de entrenamiento.
                                </p>
                                <div className="live-preview">
                                    <Form
                                        onSubmit={(e: any) => {
                                            e.preventDefault();
                                            formik.handleSubmit();
                                            return false;
                                        }}
                                    >
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="description" className="form-label font-bold">
                                                        Descripción de la marca
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        name="description"
                                                        className="form-control"
                                                        rows={5}
                                                        placeholder={descriptionPlaceholder}
                                                        value={formik.values.description}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.description && formik.touched.description)}
                                                    />
                                                    {formik.errors.description && formik.touched.description ? (
                                                        <FormFeedback type="invalid" className="d-block">
                                                            {formik.errors.description}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="additional_instructions" className="form-label font-bold">
                                                        Instrucciones adicionales
                                                    </Label>
                                                    <Input
                                                        type="textarea"
                                                        name="additional_instructions"
                                                        className="form-control"
                                                        rows={20}
                                                        placeholder={textPlaceholder}
                                                        value={formik.values.additional_instructions}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.additional_instructions && formik.touched.additional_instructions)}
                                                    />
                                                    {formik.errors.additional_instructions && formik.touched.additional_instructions ? (
                                                        <FormFeedback type="invalid" className="d-block">
                                                            {formik.errors.additional_instructions}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3 d-flex align-items-center">
                                                    <Label className="mb-0 me-3">
                                                        Habilitar respuestas sobre información de pedidos
                                                    </Label>
                                                    <div className="form-check form-switch">
                                                        <Input
                                                            type="switch"
                                                            role="switch"
                                                            name="tools.retrieve_store_orders_history"
                                                            className="form-check-input"
                                                            style={{
                                                                width: '60px',
                                                                height: '30px',
                                                                cursor: 'pointer',
                                                            }}
                                                            checked={formik.values.tools.retrieve_store_orders_history}
                                                            onChange={formik.handleChange}
                                                            disabled={loading}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3 d-flex align-items-center">
                                                    <Label className="mb-0 me-3">
                                                        Habilitar respuestas sobre métodos de pago
                                                    </Label>
                                                    <div className="form-check form-switch">
                                                        <Input
                                                            type="switch"
                                                            role="switch"
                                                            name="tools.retrieve_store_payment_methods"
                                                            className="form-check-input"
                                                            style={{
                                                                width: '60px',
                                                                height: '30px',
                                                                cursor: 'pointer',
                                                            }}
                                                            checked={formik.values.tools.retrieve_store_payment_methods}
                                                            onChange={formik.handleChange}
                                                            disabled={loading}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3 d-flex align-items-center">
                                                    <Label className="mb-0 me-3">
                                                        Responder consultas sobre productos utilizando información del ecommerce
                                                    </Label>
                                                    <div className="form-check form-switch">
                                                        <Input
                                                            type="switch"
                                                            role="switch"
                                                            name="tools.retrieve_store_products"
                                                            className="form-check-input"
                                                            style={{
                                                                width: '60px',
                                                                height: '30px',
                                                                cursor: 'pointer',
                                                            }}
                                                            checked={formik.values.tools.retrieve_store_products}
                                                            onChange={formik.handleChange}
                                                            disabled={loading}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3 d-flex align-items-center">
                                                    <Label className="mb-0 me-3">
                                                        Habilitar asistente
                                                    </Label>
                                                    <div className="form-check form-switch">
                                                        <Input
                                                            type="switch"
                                                            role="switch"
                                                            name="is_enabled"
                                                            className="form-check-input"
                                                            style={{
                                                                width: '60px',
                                                                height: '30px',
                                                                cursor: 'pointer'
                                                            }}
                                                            checked={formik.values.is_enabled}
                                                            onChange={formik.handleChange}
                                                            disabled={loading}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="text-end">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    disabled={loading}
                                                >
                                                    {loading && (
                                                        <span className="d-flex align-items-center">
                                                            <span className="flex-grow-1 me-2">Cargando...</span>
                                                            <Spinner size="sm" className="flex-shrink-0" role="status" />
                                                        </span>
                                                    )}
                                                    {!loading && (assistant?.name !== null ? 'Actualizar asistente' : 'Crear asistente')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {assistant?.name !== null && (
                                        <>
                                            <hr />
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="file" className="form-label font-bold">
                                                            Subir archivo de entrenamiento
                                                        </Label>
                                                        <Input
                                                            type="file"
                                                            name="file"
                                                            className="form-control"
                                                            innerRef={fileInputRef}
                                                            accept=".doc,.docx,.json,.html,.pdf,.txt"
                                                            onChange={(event: any) => {
                                                                const selectedFile = event.target.files[0];
                                                                if (selectedFile) {
                                                                    // Validar tamaño del archivo (máximo 100MB)
                                                                    const maxSize = 100 * 1024 * 1024; // 100MB en bytes
                                                                    if (selectedFile.size > maxSize) {
                                                                        setFileError('El archivo no debe superar los 100MB.');
                                                                        setFile(null);
                                                                        if (fileInputRef.current) {
                                                                            fileInputRef.current.value = ''; // Reiniciar el input
                                                                        }
                                                                        return;
                                                                    }

                                                                    // Validar tipo de archivo
                                                                    const allowedTypes = [
                                                                        'application/msword', // .doc
                                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
                                                                        'application/json', // .json
                                                                        'text/html', // .html
                                                                        'application/pdf', // .pdf
                                                                        'text/plain', // .txt
                                                                    ];
                                                                    if (!allowedTypes.includes(selectedFile.type)) {
                                                                        setFileError('Tipo de archivo no permitido.');
                                                                        setFile(null);
                                                                        if (fileInputRef.current) {
                                                                            fileInputRef.current.value = ''; // Reiniciar el input
                                                                        }
                                                                        return;
                                                                    }

                                                                    // Si la validación pasa
                                                                    setFile(selectedFile);
                                                                    setFileError(null);
                                                                    console.log('Selected file:', selectedFile);
                                                                }
                                                            }}
                                                            invalid={!!fileError}
                                                        />
                                                        {fileError && (
                                                            <FormFeedback type="invalid" className="d-block">
                                                                {fileError}
                                                            </FormFeedback>
                                                        )}
                                                        <FormText color="muted">
                                                            Archivos permitidos: .doc, .docx, .json, .html, .pdf, .txt. Tamaño máximo: 100MB.
                                                        </FormText>
                                                        <FormText color="muted">
                                                            <p>Para archivos tipo hoja de cálculo, los formatos HTML y JSON son los que mejor funcionan.</p>
                                                        </FormText>
                                                    </div>
                                                    <Button
                                                        color="success"
                                                        onClick={handleFileUpload}
                                                        disabled={!file || loading || fileError}
                                                    >
                                                        {loading && (
                                                            <span className="d-flex align-items-center">
                                                                <span className="flex-grow-1 me-2">Cargando...</span>
                                                                <Spinner size="sm" className="flex-shrink-0" role="status" />
                                                            </span>
                                                        )}
                                                        {!loading && 'Subir archivo'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {files.length > 0 && (
                                                <Row>
                                                    <Col sm={12}>
                                                        <h5 className="mt-4">Archivos de entrenamiento</h5>
                                                        <Table bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Nombre</th>
                                                                    <th>Fecha de carga</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {files.map((file: any, index: number) => (
                                                                    <tr key={file.uid}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{file.filename}</td>
                                                                        <td>{new Date(file.created_at).toLocaleDateString()}</td>
                                                                        <td>
                                                                            {file.active === true && (
                                                                                <Button
                                                                                    color="danger"
                                                                                    size="sm"
                                                                                    onClick={() => handleUpdateFile(file.uid, { active: false })}
                                                                                >
                                                                                    Desactivar archivo
                                                                                </Button>
                                                                            )}
                                                                            {file.active === false && (
                                                                                <Button
                                                                                    color="success"
                                                                                    size="sm"
                                                                                    onClick={() => handleUpdateFile(file.uid, { active: true })}
                                                                                >
                                                                                    Activar archivo
                                                                                </Button>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            )}
                                        </>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default AssistantForm;
