import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { formatDateDifference, formatNumber } from 'helpers/format';
import { format } from 'date-fns';

interface OrderTableProps {
    orders?: any[],
    title?: string,
    showTitle?: boolean,
    loading?: boolean,
    fullDetails?: boolean,
}

const StatusName: { [key: string]: string } = {
    CONVERTED: 'Convertido',
    CONFIRMED: 'Orgánico',
    CREATED: 'En checkout',
    ABANDONED: 'Abandonado',
    PAYMENT_PENDING: 'Pendiente de pago',
}

const StatusColor: { [key: string]: string } = {
    CONVERTED: 'success',
    CONFIRMED: 'info',
    CREATED: 'warning',
    ABANDONED: 'danger',
    PAYMENT_PENDING: 'secondary',
}

const OrderTable = ({ orders, title, showTitle = true, loading = false, fullDetails = true }: OrderTableProps) => {
    console.log("Orders:", orders);
    return (
        <div className="table-responsive">
            <table className="table table-centered align-middle table-nowrap mb-0">
                <thead className="text-muted table-light">
                    <tr>
                        <th scope="col" className="text-center">Número de pedido</th>
                        <th scope="col" className="text-center">Contacto</th>
                        <th scope="col" className="text-center">Estado</th>
                        <th scope="col" className="text-center">Fecha del pedido</th>
                        {fullDetails && <th scope="col" className="text-center">Fecha de pago</th>}
                        {fullDetails && <th scope="col" className="text-center">Fecha ult. mensaje</th>}
                        {fullDetails && <th scope="col" className="text-center">Diferencia horaria</th>}
                        <th scope="col" className="text-center">Monto</th>
                    </tr>
                </thead>
                <tbody>
                    {(orders == null || loading) &&
                        <>
                            {Array(5).fill(0).map((_, index) => <tr key={index}>
                                {Array(8).fill(0).map((_, sIndex) => <td key={sIndex}>
                                    <p className="card-text placeholder-glow">
                                        <span className="placeholder col-12"></span>
                                    </p>
                                </td>)}
                            </tr>)}
                        </>
                    }
                    {(orders || []).map((item, key) => {
                        console.log("Order item:", item);
                        console.log("Order notifications:", item.notifications);


                        return (
                            <tr key={key}>
                                <td className="text-center text-primary">
                                    {item.store_order_number}
                                </td>
                                <td className="text-center text-primary">
                                    {(item.email || item.phone || item.phone_shipping || item.phone_billing || 'No contactable')
                                        .replace(/\s+/g, '')}
                                </td>
                                <td className="text-center">
                                    <span className={"badge bg-" + StatusColor[item.status] + "-subtle fs-12 text-" + StatusColor[item.status]}>{StatusName[item.status]}</span>
                                </td>
                                <td className="text-center">
                                    <span className="text-dark">{format(new Date(item.created_at), 'dd/MM/yyyy')}</span>
                                </td>

                                {fullDetails && <td className="text-center">
                                    <span className="text-dark">{item.paid_at
                                        ? format(new Date(item.paid_at), 'dd/MM/yyyy')
                                        : '-'}
                                    </span>
                                </td>}
                                {fullDetails && <td className="text-center">
                                    <span className="text-dark">{item.notifications.length > 0
                                        ? format(new Date(item.notifications[0].sent_at), 'dd/MM/yyyy')
                                        : '-'}
                                    </span>
                                </td>}
                                {fullDetails && <td className="text-center">
                                    <span className="text-dark">{item.notifications.length > 0 && item.status == 'CONVERTED'
                                        ? formatDateDifference(new Date(item.notifications[0].sent_at), new Date(item.paid_at))
                                        : '-'}
                                    </span>
                                </td>}
                                <td className="text-center">{item.currency} {formatNumber(item.checkout_total_price || item.total_price)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default OrderTable;
