

export const TICKET_COLUMNS = [
    {
        title: "Pendientes",
        className: "bg-warning text-white text-center rounded",
        style: {
            minWidth: '200px',
        },
    },
    {
        title: "En progreso",
        className: "bg-light text-center rounded",
        style: {
            minWidth: '200px',
        },
    },
    {
        title: "E. Respuesta",
        className: "bg-secondary text-white text-center rounded",
        style: {
            minWidth: '200px',
        },
    },
    {
        title: "Stand by",
        className: "bg-secondary text-white text-center rounded",
        style: {
            minWidth: '200px',
        },
    },
    {
        title: "Resueltos",
        className: "bg-primary text-white text-center rounded",
        style: {
            minWidth: '200px',
        },
    },
    {
        title: "Cerrados",
        className: "bg-success text-white text-center rounded",
        style: {
            minWidth: '200px',
        },
    }
]