import CampaignTable from 'Components/Campaigns/CampaignTable';
import BreadCrumb from 'Components/Common/BreadCrumb';
import Pagination from 'Components/Common/Pagination';
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { selectCampaigns, selectGroup, selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import GroupMenu from '../../Components/Campaigns/GroupMenu';
import useCurrentStore from 'Components/Hooks/CurrentStore';


const CampaignList = () => {
    useCurrentStore()

    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const { currentGroup } = useSelector(selectGroup)
    const campaigns = useSelector(selectCampaigns)

    const fetchPage = useCallback((page: any) => {
        if (currentStoreId) {
            dispatch(
                getCampaigns({
                    storeId: currentStoreId,
                    skip: page,
                    system_only: false,
                    campaign_group_id: currentGroup?.id,
                })
            )
        }
    }, [currentStoreId, currentGroup])

    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId, currentGroup]);

    document.title = 'Mis campañas | Burbuxa'

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Mis campañas" pageTitle="Campañas" />

                <Container fluid>
                    <Row>
                        <Col xxl={12} className='text-end mb-2'>
                            <Link to='/campaigns/new' className="btn btn-primary">
                                <span className="mdi mdi-plus"></span> Crear Campaña
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={3}>
                            <GroupMenu />
                        </Col>
                        <Col xxl={9}>
                            <Card>
                                <CardBody className='p-0'>
                                    <CampaignTable loading={campaigns.status === 'loading'} items={campaigns.campaignItems} />

                                    <Pagination
                                        total={campaigns.limit !== 0 ? Math.ceil(campaigns.total / campaigns.limit) : 0}
                                        data={campaigns.campaignItems}
                                        setCurrentPage={(page: any) => fetchPage((page - 1) * campaigns.limit)}
                                        currentPage={campaigns.limit !== 0 ? Math.ceil(campaigns.skip / campaigns.limit) + 1 : 0}
                                        perPageData={campaigns.limit}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    </React.Fragment>
}

export default CampaignList