import { Col, Input, Label, Button } from "reactstrap"
import 'react-customize-token-input/dist/react-customize-token-input.css';
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es"
import TokenInput from 'react-customize-token-input'
import CampaignConditionConfig from "./CampaignConditionConfig"
import { useSelector } from "react-redux";
import { selectStore } from "selectors";
import Select from 'react-select';
import TimeInput from "./TimeInput";
import { capitalize } from "lodash";


const MessageTranslated: { [key: string]: string } = {
    'action': 'Acción',
    'criteria': 'Criterio',
    'when': 'Cuando',
    'if_abandoned': 'Si ha abandonado el carrito',
    'if_purchase': 'Si ha comprado',
    'shipping_status': 'Estado de envío',
    'time': 'Tiempo',
    'days': 'Días',
    'customers_on_creation': 'Si es un nuevo cliente',
    'change_type': 'Tipo de actualización',
    'shipping_event': 'Evento de envío',
    'hours': 'Horas',
    'minutes': 'Minutos',
    'any_product': 'Cualquier producto',
    'product': 'Cierto producto',
    'immediatly': 'Inmediatamente',
    'payment_method': 'Cierto método de pago',
    'skus': 'SKUs',
    'frequency': 'Frecuencia',
    'each_time': 'Cada vez',
    'last_time': 'Por última vez',
    'spent': 'Gastado cierto monto',
    'at_least': 'Como mínimo',
    'less_than': 'Menos de',
    'more_than': 'Más de',
    'less_or_equal': 'No más de',
    'at_maximum': 'Como máximo',
    'spent_amount': 'Monto',
    'exactly': 'Exactamente',
    'user': 'Comportamiento de usuario',
    'quantity': 'Cantidad',
    'interval': 'Intervalo',
    'date_a': 'Fecha',
    'date_b': 'Fecha hasta',
    'history': 'Toda la historia',
    'range': 'Rango',
    'before': 'Antes de',
    'after': 'Despues de',
    'quantity_time': 'Cantidad de veces',
    'spent_type': 'Tipo de gasto',
    'avg': 'Promedio',
    'total': 'Total',
    'amount': 'Monto',
    'exactly_day': 'Exactamente el',
    'between': 'Entre fechas',
    'weeks': 'Semanas',
    'months': 'Meses',
    'years': 'Años',
    'preparing': 'En preparación',
    'sent': 'Enviado',
    'failed': 'Fallido',
    'delivered': 'Entregado',
    'all': 'Todos',
    'normal_sent': 'Envio normal',
    'before_24_hours': 'Enviar solo si no han transcurrido 22 horas',
    'wait_time': 'Tiempo de espera desde primer mensaje',
    'mode': 'Modo de envío',
    'last_time_before': 'Por última vez antes de',
    'last_time_after': 'Por última vez después de',
    'last_time_between': 'Por última vez entre',
    'category': 'un producto de cierta categoría',
    'product_categories': 'Categorías de productos',
    'spending_limit': 'Limite de la campaña',
    'engaged_customers': 'Clientes activos (engaged)',
    'include_created': 'Incluir perfiles creados en el periodo',
    'include_updated': 'Incluir perfiles actualizados en el periodo',
    'include_buyers': 'Incluir perfiles con alguna compra en el periodo',
    'include_abandoned': 'Incluir perfiles con algún carrito abandonado en el periodo',
    'exclude_recent_buyers_days': 'Excluir perfiles con alguna compra en los últimos X días',
    'exclude_active_subscribers': 'Excluir perfiles que tengan al menos una suscripción activa',
}

const getLabel = (name: string) => {
    const code = name.toLowerCase()
    return code in MessageTranslated ? MessageTranslated[code] : name
}



const CampaignCondition = ({ evaluation_type, target, formik }: any) => {
    const { paymentMethods, productCategories } = useSelector(selectStore)

    const fields = CampaignConditionConfig[evaluation_type] && CampaignConditionConfig[evaluation_type][target]
    if (!fields) {
        return <></>
    }
    console.log('Current formik values:', formik.values);
    return fields.map((each: any) => {
        let component;
        if (each.showIf && !each.showIf(formik.values)) {
            if (formik.values[each.name] !== null) {
            }
            return null;
        }
        switch (each.field) {
            case 'time':
                component = <TimeInput
                    name={each.name}
                    formik={formik}
                />
                break
            case 'dropdown':
                component = <Input
                    name={each.name}
                    type="select"
                    required
                    value={formik.values[each.name] ?? ''}
                    onChange={(e) => formik.setFieldValue(each.name, e.target.value)}>
                    <option value="">-- Seleccione --</option>
                    {each.values.map((opt: any) => <option value={opt} key={opt}>{getLabel(opt)}</option>)}
                </Input>
                break

            case 'checkbox':
                console.log(`${each.name}:`, formik.values[each.name] ?? 'undefined');
                component = <Input
                    type="checkbox"
                    name={each.name}
                    checked={formik.values[each.name]}
                    onChange={(e) => formik.setFieldValue(each.name, e.target.checked)}
                />
                break
            case 'payment_method':
                component = <Input
                    type="select"
                    name={each.name}
                    required
                    value={formik.values[each.name] ?? ''}
                    onChange={(e) => formik.setFieldValue(each.name, e.target.value)}>
                    <option value="">-- Seleccione --</option>
                    {paymentMethods.map((opt: any) =>
                        <option value={opt} key={opt}>{capitalize(opt)}</option>
                    )}
                </Input>
                break
            case 'product_categories':
                const categoryOptions = Object.entries(productCategories.categories).map(([name, platform_id]) => ({
                    label: name,
                    value: String(platform_id), // Convert platform_id to string if necessary
                }));

                component = (
                    <Select
                        name={each.name}
                        isMulti
                        options={categoryOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Seleccione categorías"
                        value={categoryOptions.filter(option => (formik.values[each.name] ?? []).includes(option.value))}
                        onChange={(selectedOptions: Array<{ value: string; label: string }> | null) => {
                            const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
                            console.log('Setting field value:', each.name, values); // Add this line
                            formik.setFieldValue(each.name, values);
                        }}
                    />
                );
                break;
            case 'number':
                component = <Input
                    type="number"
                    name={each.name}
                    required
                    value={formik.values[each.name] ?? ''}
                    onChange={(e) => formik.setFieldValue(each.name, e.target.value)} />
                break
            case 'label':
                component = <></>
                break
            case 'tags':
                const loadTagsCSV = (file: File, fieldName: string) => {
                    const reader = new FileReader()
                    reader.onload = (e) => {

                        const csvdata = e.target?.result;

                        if (csvdata) {
                            const rowData = (String(csvdata).split('\n')
                                .map((x) => x.trim())
                                .filter((x) => !!x));

                            formik.setFieldValue(each.name, rowData)
                        }
                    }
                    reader.readAsText(file);
                }
                component = <>
                    <TokenInput
                        tokenValues={formik.values[each.name] || []}
                        onTokenValuesChange={(value) => {
                            formik.setFieldValue(each.name, value)
                        }}
                        placeholder="Ingrese los skus, presione enter por cada uno"
                    />
                    <div className="text-end py-1">
                        <Button size="sm" onClick={() => formik.setFieldValue(each.name, "")}>Borrar SKUs</Button>
                    </div>
                    <Input type="file" placeholder="Desde .CSV" onChange={(f: any) => {
                        loadTagsCSV(f.target.files[0], each.name)
                    }} />
                </>
                break
            case 'date':
                component = <Flatpickr
                    className="form-control"
                    required
                    value={formik.values[each.name]}
                    options={{
                        locale: Spanish,
                        dateFormat: "d M, Y",
                    }}
                    onChange={(selectedDates: any, dateStr: any, instance: any) => {
                        formik.setFieldValue(each.name, selectedDates[0])
                    }}
                />

        }
        return <Col sm={3} className="mb-3">
            <Label>{getLabel(each.name)}</Label>
            {component}
        </Col>
    })
}

export default CampaignCondition
