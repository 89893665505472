import { createSlice } from '@reduxjs/toolkit';
import {
    getAssistant,
    createAssistant,
    updateAssistant,
    uploadAssistantFile,
    updateAssistantFile,
} from './thunk';

interface AssistantState {
    assistant: any;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: any;
}

const initialState: AssistantState = {
    assistant: null,
    status: 'idle',
    error: null,
};

const assistantSlice = createSlice({
    name: 'assistant',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAssistant.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAssistant.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.assistant = action.payload;
            })
            .addCase(getAssistant.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(createAssistant.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createAssistant.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.assistant = action.payload;
            })
            .addCase(createAssistant.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateAssistant.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAssistant.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.assistant = action.payload;
            })
            .addCase(updateAssistant.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(uploadAssistantFile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(uploadAssistantFile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (state.assistant) {
                    state.assistant.files = [...(state.assistant.files || []), action.payload];
                }
            })
            .addCase(uploadAssistantFile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateAssistantFile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAssistantFile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (state.assistant) {
                    state.assistant.files = state.assistant.files.map(
                        (file: any) => file.uid === action.meta.arg.fileUid ? action.payload : file
                    );
                }
            })
            .addCase(updateAssistantFile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default assistantSlice.reducer;