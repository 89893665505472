import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "config";

const { api } = config;

// default
//"https://apiv2.whatacart.ai/v1/";//
axios.defaults.baseURL = process.env.REACT_APP_WHATA_API_URL; //api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const authUser: any = sessionStorage.getItem("authUser")
const token = JSON.parse(authUser) ? JSON.parse(authUser).accessToken : null;

if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    if (error.response.status === 401) {
      const search = window.location.search
      window.location.href = '/login' + search;
    }

    return error;
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {

  /**
   * Fetches data from given url
   */

  get = (url: string, params?: any, config?: any): Promise<AxiosResponse> => {
    return axios.get(url, {
      params,
      ...(config || {}),
    });
  };
  /**
   * post given data to url
   */
  create = (url: string, data: any, params?: any) => {
    return axios.post(url, data, params);
  };
  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  put = (url: string, data: any) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };