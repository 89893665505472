import Board from "Components/Board/Board";
import TicketItem from "Components/CRM/TicketItem";
import UserAssignationModal from "Components/CRM/UserAssignationModal";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Container, Input, Row } from "reactstrap";
import { selectProfile, selectUsers } from "selectors";
import { getTickets, getUsers, updateTicket } from "slices/thunks";
import { BasicUser, ConversationContact, GenericContact, Ticket, TicketStatusColumns } from "types/Dashboard";
import { TICKET_COLUMNS } from "./constants";
import useCurrentStore from "Components/Hooks/CurrentStore";
import ContactDetail from "Components/CRM/ContactDetail";



const Tickets = () => {
    useCurrentStore()
    const { ticketItems } = useSelector((state: any) => state.Tickets);
    const navigate = useNavigate();
    const { currentStoreId, user } = useSelector(selectProfile);
    const { users } = useSelector(selectUsers);
    const [currentTicket, setCurrentTicket] = useState<Ticket | null>(null)
    const [currentContact, setCurrentContact] = useState<ConversationContact | null>(null)
    const [userAssignationModalOpen, setUserAssignationModalOpen] = useState(false);
    const storeId = currentStoreId;
    const dispatch: any = useDispatch();
    const [filterUser, setFilterUser] = useState<string | null>(null);

    useEffect(() => {
        if (storeId) {
            dispatch(
                getTickets({
                    storeId: storeId,
                    user_uid: filterUser,
                    all: true
                })
            );
        }
    }, [dispatch, storeId, filterUser]);

    useEffect(() => {
        if (storeId) {
            dispatch(getUsers({ storeId: storeId }));
        }
    }, [dispatch, storeId]);

    const onUpdateTicket = useCallback((ticket: Ticket) => {
        dispatch(updateTicket({
            storeId: storeId,
            ticketId: ticket.id,
            data: {
                id: ticket.id,
                status: ticket.status,
            }
        }));
    }, [dispatch, storeId]);

    const onArchiveTicket = useCallback((ticket: Ticket) => {
        dispatch(updateTicket({
            storeId: storeId,
            ticketId: ticket.id,
            data: {
                status: "ARCHIVED"
            }
        }));
    }, [dispatch, storeId]);

    const onUserAssignationShowModal = useCallback((ticket: Ticket) => {
        setCurrentTicket(ticket);
        setUserAssignationModalOpen(true);
    }, []);

    const onUserAssignationConfirm = useCallback((user: BasicUser) => {
        dispatch(updateTicket({
            storeId: storeId,
            ticketId: currentTicket?.id,
            data: {
                assigned_user_id: user.uid
            }
        }));
        setCurrentTicket(null);
        setUserAssignationModalOpen(false);
    }, [currentTicket, storeId]);

    const items = useMemo(() => (ticketItems || []).map((ticket: Ticket) =>
        TicketItem(ticket, onArchiveTicket, navigate, onUserAssignationShowModal)),
        [ticketItems, onArchiveTicket, navigate, onUserAssignationShowModal]
    );

    return <div className="page-content">
        <Container fluid>
            <BreadCrumb title="Tickets" pageTitle="CRM" />

            <Container fluid>
                <div className="card">
                    <div className="border-0 rounded card-header">
                        <div className="g-2 row">
                            <div className="col-xl-3">
                                <div className="search-box">
                                    <Input type="select" onChange={(e) => {
                                        setFilterUser(e.target.value ? e.target.value : null);
                                    }}>
                                        <option value="">-- Ver todos los tickets --</option>
                                        <option value={user?.uid}>Solo asignados a mi</option>
                                        {(users || [])
                                            .filter((each: BasicUser) => each.uid !== user?.uid)
                                            .map(
                                                (each: BasicUser) => <option value={each.uid}>Solo asignados a {each.first_name} {each.last_name}</option>
                                            )}
                                    </Input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col sm={currentContact ? 8 : 12} style={{ overflow: 'auto' }}>
                        <Board
                            onClick={(item) => {
                                const currTicket = (ticketItems || []).find((i: Ticket) => i.id === item.id)
                                console.log('item', currTicket)
                                setCurrentContact(currTicket.conversation_contact)
                            }}
                            onItemMoved={(item, columnIndex) => {
                                const nItems = [...items];
                                const nItem = nItems.find(i => i.id === item.id);
                                if (nItem !== undefined) {
                                    nItem.columnIndex = columnIndex;
                                    nItem.status = TicketStatusColumns[columnIndex];
                                    onUpdateTicket(nItem);
                                }
                            }}
                            columns={TICKET_COLUMNS}
                            items={items}
                        />
                    </Col>
                    {currentContact && <Col sm={4}>
                        <ContactDetail
                            onClose={() => setCurrentContact(null)}
                            contact={{
                                first_name: currentContact.name,
                                phone: currentContact.phone,
                                id: currentContact.id,
                                created_at: currentContact.created_at,
                            }}
                        />
                    </Col>}
                </Row>
            </Container>
            <UserAssignationModal
                isOpen={userAssignationModalOpen}
                toggle={() => setUserAssignationModalOpen(false)}
                onUserAssignation={onUserAssignationConfirm}
            />
        </Container>
    </div>
}

export default Tickets; 