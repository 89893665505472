import CampaignDropDown from 'Components/Campaigns/CampaignDropDown';
import CampaignStatusDropDown from 'Components/Campaigns/CampaignStatusDropDown';
import CampaignTable from 'Components/Campaigns/CampaignTable';
import BreadCrumb from 'Components/Common/BreadCrumb';
import Pagination from 'Components/Common/Pagination';
import useCurrentStore from 'Components/Hooks/CurrentStore';
import OrderTable from 'Components/Orders/OrderTable';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { selectCampaigns, selectOrder, selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { getOrderList } from 'slices/order/thunk';

const OrderList = () => {
    useCurrentStore()
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const [campaignId, setCampaignId] = useState<any>(null)
    const [status, setStatus] = useState<any>(null)
    const campaigns = useSelector(selectCampaigns)
    const orders = useSelector(selectOrder)

    const fetchPage = useCallback((page: any) => {
        if (currentStoreId) {
            dispatch(
                getOrderList({
                    storeId: currentStoreId,
                    skip: page,
                    campaign_id: campaignId,
                    status,
                })
            )
        }
    }, [currentStoreId, campaignId, status])

    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId]);


    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId, campaignId, status]);

    document.title = 'Todos los pedidos | Burbuxa'

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Listar órdenes" pageTitle="Órdenes" />

                <Container fluid>
                    <Row>
                        <Col xxl={12} className="text-end mb-2 d-flex justify-content-end gap-2">
                            <CampaignStatusDropDown placeholder='Filtrar por estado del pedido' onSelect={(c) => setStatus(c)} />
                            <CampaignDropDown placeholder='Filtrar por campaña' onSelect={(c) => setCampaignId(c ? c.id : null)} value={campaignId} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <CardBody className="p-0">
                                    <OrderTable loading={orders.status == 'loading'} orders={orders.orderItems} showTitle={false} />
                                    <Pagination
                                        total={orders.limit !== 0 ? Math.ceil(orders.total / orders.limit) : 0}
                                        data={orders.orderItems}
                                        setCurrentPage={(page: any) => fetchPage((page - 1) * orders.limit)}
                                        currentPage={orders.limit !== 0 ? Math.ceil(orders.skip / orders.limit) + 1 : 0}
                                        perPageData={orders.limit}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    </React.Fragment>
}

export default OrderList 
