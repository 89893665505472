import auth0 from 'auth0-js';

const webAuth = new auth0.WebAuth({
    domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
    clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
});


export const dbConnection = `${process.env.REACT_APP_AUTH0_DB_CONNECTION}`;
export const audience = `${process.env.REACT_APP_AUTH0_AUDIENCE}`;
export default webAuth;

