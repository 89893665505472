import CampaignTable from 'Components/Campaigns/CampaignTable';
import BreadCrumb from 'Components/Common/BreadCrumb';
import Pagination from 'Components/Common/Pagination';
import useCurrentStore from 'Components/Hooks/CurrentStore';
import TemplateTable from 'Components/Templates/TemplateTable';
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { selectCampaigns, selectProfile, selectTemplate } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { getTemplates } from 'slices/templates/thunk';


const TemplateList = () => {
    useCurrentStore()
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile)
    const templates = useSelector(selectTemplate)

    const fetchPage = useCallback((page: any) => {
        if (currentStoreId) {
            dispatch(
                getTemplates({
                    storeId: currentStoreId,
                    skip: page,
                    system_only: false,
                })
            )
        }
    }, [currentStoreId])

    useEffect(() => {
        if (currentStoreId) {
            fetchPage(0)
        }
    }, [currentStoreId]);

    document.title = 'Mis mensajes | Burbuxa'

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Mis mensajes" pageTitle="Mensajes" />

                <Container fluid>
                    <Row>
                        <Col xxl={12} className='text-end mb-2'>
                            <Link to='/templates/new' className="btn btn-primary">
                                <span className="mdi mdi-plus"></span> Crear mensaje
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <CardBody className='p-0'>
                                    <TemplateTable loading={templates.status === 'loading'} items={templates.templateItems} />

                                    <Pagination
                                        total={templates.limit !== 0 ? Math.ceil(templates.total / templates.limit) : 0}
                                        data={templates.templateItems}
                                        setCurrentPage={(page: any) => fetchPage((page - 1) * templates.limit)}
                                        currentPage={templates.limit !== 0 ? Math.ceil(templates.skip / templates.limit) + 1 : 0}
                                        perPageData={templates.limit}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    </React.Fragment>
}

export default TemplateList 