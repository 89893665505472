import { format } from "date-fns";
import { Badge, Button, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from "reactstrap"
import { Ticket, TicketStatusColor, TicketStatusName } from "types/Dashboard";


interface TicketTableProps {
    tickets: Ticket[];
    onSelectTicket?: (ticket: Ticket) => void;
    onUpdateTicket?: (ticket: Ticket, status: string) => void;
}

const TicketTable = ({ tickets, onSelectTicket, onUpdateTicket }: TicketTableProps) => {
    return <div className="table-responsive table-hover">
        <Table>
            <thead className="text-muted table-light">
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Fecha</th>
                    <th>Estado</th>
                    <th>Asignado a</th>
                    <th>Título</th>
                    {onUpdateTicket && <th>Acciones</th>}
                </tr>
            </thead>
            <tbody>
                {tickets.map((ticket) => (
                    <tr key={ticket.id}>
                        <td>
                            <Button onClick={() => onSelectTicket?.(ticket)} size="sm" color="primary" outline>
                                <i className="ri-whatsapp-line"></i>
                            </Button>
                        </td>
                        <td>{ticket.id}</td>
                        <td>{format(new Date(ticket.created_at), 'dd/MM/yyyy hh:mm')}</td>
                        <td><Badge color={TicketStatusColor[ticket.status]}>{TicketStatusName[ticket.status]}</Badge></td>
                        <td>{ticket.assigned_user?.first_name} {ticket.assigned_user?.last_name}</td>
                        <td className="text-wrap">{ticket.text}</td>
                        {onUpdateTicket && <td>
                            <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="cursor-pointer btn btn-sm btn-outline-primary">
                                    Actualizar estado <i className="ri-arrow-down-s-line"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => onUpdateTicket(ticket, 'NEW')}>Pendiente</DropdownItem>
                                    <DropdownItem onClick={() => onUpdateTicket(ticket, 'IN_PROGRESS')}>En curso</DropdownItem>
                                    <DropdownItem onClick={() => onUpdateTicket(ticket, 'WAITING_ON_CUSTOMER')}>Esperando respuesta</DropdownItem>
                                    <DropdownItem onClick={() => onUpdateTicket(ticket, 'STAND_BY')}>Stand by</DropdownItem>
                                    <DropdownItem onClick={() => onUpdateTicket(ticket, 'RESOLVED')}>Resuelto</DropdownItem>
                                    <DropdownItem onClick={() => onUpdateTicket(ticket, 'CLOSED')}>Cerrado</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </td>}
                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
}

export default TicketTable;