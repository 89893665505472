import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectCampaigns, selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { ICampaign } from 'types/Dashboard';

const VARIABLES = [
    { id: 'first_name', label: 'Nombre' },
    { id: 'abandoned_url', label: 'URL Carrito Abandonado' },
    { id: 'product', label: 'Nombre del producto' },
    { id: 'shipping_company', label: 'Empresa de envío' },
    { id: 'shipping_status', label: 'Estado de envío' },
    { id: 'shipping_tracking_number', label: 'ID de seguimiento de envío' },
    { id: 'shipping_tracking_url', label: 'URL de seguimiento de envío' },
    { id: 'order_status_url', label: 'URL de estado de pedido' },
    { id: 'order_amount', label: 'Monto de orden' },
    { id: 'order_number', label: 'Número de orden' },
    { id: 'order_amount_5off', label: 'Monto de orden (-5%)' },
    { id: 'order_amount_10off', label: 'Monto de orden (-10%)' },
    { id: 'order_amount_15off', label: 'Monto de orden (-15%)' }
]

interface TemplateVariableDropdownProps {
    onSelectVariable: (variable: string) => void
    options?: { id: string, label: string }[]
}

const TemplateVariableDropdown = ({ onSelectVariable, options }: TemplateVariableDropdownProps) => (
    <ButtonGroup>
        <UncontrolledDropdown>
            <DropdownToggle tag="a" className="dropdown-btn btn btn-sm btn-primary" role="button">
                <i className='mdi mdi-variable'></i> Insertar variable <i className='mdi mdi-chevron-down'></i>
            </DropdownToggle>
            <DropdownMenu>
                {(options || VARIABLES).map((each: any) =>
                    <DropdownItem key={each.id} onClick={() => onSelectVariable(each.id)}>{each.label}</DropdownItem>)
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    </ButtonGroup>
)


export default TemplateVariableDropdown;
