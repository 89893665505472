import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'

interface DisplayMessageProps {
  url: string
}

const FileMessage: React.FC<DisplayMessageProps> = ({ url }) => {
  const [selectedFile, setSelectedFile] = useState<Blob | null>(null)

  function getFilenameFromUrl(url: any) {
    const urlObj = new URL(url)
    const fileName = urlObj.searchParams.get('fileName')
    return fileName ? decodeURIComponent(fileName) : null
  }

  const fileName = getFilenameFromUrl(url)

  return (
    <div className="file-download-container">
      <i
        style={{ fontSize: 30 }}
        className="mdi mdi-file-cloud file-download-icon"
      />
      <div className="file-download-spancon">
        <span style={{ fontSize: 17 }}>{fileName}</span>
      </div>
      <a
        href={url}
        target="_blank"
        download={fileName}
        className="file-download-link"
      >
        <Button color="primary">
          <i style={{ fontSize: 20 }} className="mdi mdi-download" />
        </Button>
      </a>
    </div>
  )
}

export default FileMessage
