import ContactDetail from "Components/CRM/ContactDetail";
import ContactTable from "Components/CRM/ContactTable";
import BreadCrumb from "Components/Common/BreadCrumb";
import useCurrentStore from "Components/Hooks/CurrentStore";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Pagination, Row, Table } from "reactstrap";
import { selectCrm, selectProfile } from "selectors";
import { getCrmContacts } from "slices/crm/thunk";
import { GenericContact } from "types/Dashboard";

const Contacts = () => {
    useCurrentStore()
    const crm = useSelector(selectCrm)
    const dispatch: any = useDispatch()
    const { currentStoreId } = useSelector(selectProfile);
    const [selectedContact, setSelectedContact] = useState<GenericContact | null>(null)
    const fetchContacts = useCallback((skip: number) => {
        console.log('fetchContacts', skip)
        dispatch(
            getCrmContacts(
                {
                    storeId: currentStoreId,
                    skip,
                    limit: 10,
                }
            )
        )
    }, [dispatch, currentStoreId])

    useEffect(() => {
        if (currentStoreId) {
            fetchContacts(0)
        }
    }, [currentStoreId, fetchContacts])

    return <div className="page-content">
        <BreadCrumb title="Contactos" pageTitle="CRM" />
        <Row>
            <Col xxl={selectedContact ? 6 : 12}>
                <div className="d-flex flex-column m-0 p-0 gap-2">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Contactos</h5>
                        </div>
                        <div className="card-body m-0 p-0">
                            <ContactTable
                                contacts={crm.contacts}
                                limit={crm.limit}
                                skip={crm.skip}
                                total={crm.total}
                                fetchContacts={fetchContacts}
                                onSelectContact={(contact: GenericContact) => {
                                    setSelectedContact(contact)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Col>
            <Col xxl={selectedContact ? 6 : 0}>
                <ContactDetail contact={selectedContact} />
            </Col>
        </Row>
    </div>
}

export default Contacts;