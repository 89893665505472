import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { selectCampaigns, selectGroup, selectProfile } from 'selectors';
import { getCampaigns } from 'slices/campaign/thunk';
import { getGroups } from 'slices/groups/thunk';

interface CurrencyStoreDropdownProps {
    placeholder: string
    onSelect: (group: any) => void
    value: any
}


const CurrencyStoreDropdown = ({ placeholder, onSelect, value }: CurrencyStoreDropdownProps) => {
    let displayName;
    const dispatch: any = useDispatch()
    const profile = useSelector(selectProfile)
    const [currencySelected, setCurrencySelected] = useState<any>(value)

    const Options: Record<string, string> = useMemo(() => {
        const _opts: Record<string, string> = {
            'USD': 'USD',
        }

        if (profile.currentStoreCurrency !== 'USD') {
            _opts['STORE'] = profile.currentStoreCurrency
        }
        return _opts;
    }, [profile.currentStoreCurrency])

    useEffect(() => {
        if (profile.currentStoreId) {
            dispatch(
                getGroups({
                    storeId: profile.currentStoreId
                })
            )
        }
    }, [profile.currentStoreId])

    const _onSelect = (selected: any) => {
        onSelect(selected)
        setCurrencySelected(selected)
    }

    if (currencySelected) {
        displayName = <span><b>{Options[currencySelected]}</b></span>
    } else {
        displayName = placeholder || 'Seleccione una moneda'
    }

    return (
        <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle tag="a" className="dropdown-btn btn btn-primary" role="button">
                    <i className='mdi mdi-cash'></i> {displayName} <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => _onSelect(null)} className="text-center text-muted">Eliminar filtro</DropdownItem>
                    {Object.keys(Options).map((key: any, index: any) => <DropdownItem key={index} onClick={() => _onSelect(key)}>{Options[key]}</DropdownItem>)}
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    );
};

export default CurrencyStoreDropdown;
