import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { selectProfile, selectUsers } from "selectors";
import { getUsers } from "slices/thunks";
import { BasicUser } from "types/Dashboard";


interface UserAssignationModalProps {
    isOpen: boolean;
    toggle: () => void;
    onUserAssignation: (user: BasicUser) => void;
}

const UserAssignationModal = ({ isOpen, toggle, onUserAssignation }: UserAssignationModalProps) => {
    const dispatch: any = useDispatch();
    const { users } = useSelector(selectUsers);
    const { currentStoreId } = useSelector(selectProfile);

    useEffect(() => {
        if (currentStoreId) {
            dispatch(
                getUsers({ storeId: currentStoreId })
            );
        }
    }, [currentStoreId]);

    console.log("users", users);

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader className="text-center">
            <h5>Asignar usuario</h5>
        </ModalHeader>
        <ModalBody className="p-0">
            <ListGroup>
                {users.map((user: any) => (
                    <ListGroupItem
                        action
                        key={user.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => onUserAssignation(user)}
                    >
                        {user.first_name} {user.last_name}
                    </ListGroupItem>
                ))}
            </ListGroup>
        </ModalBody>
        <ModalFooter className="m-0 p-0">
            <Button color="primary" onClick={toggle}>Cerrar</Button>
        </ModalFooter>
    </Modal>
}

export default UserAssignationModal;