import React, { useState, useEffect } from 'react'

interface AudioMessagePlayerProps {
  url: string
}

const AudioMessagePlayer: React.FC<AudioMessagePlayerProps> = ({ url }) => {
  const [audio] = useState(new Audio(url))
  const [playing, setPlaying] = useState(false)

  const togglePlayer = () => {
    setPlaying((prev) => !prev)
  }

  useEffect(() => {
    playing ? audio.play() : audio.pause()

    return () => {
      audio.pause()
      audio.currentTime = 0
    }
  }, [playing, audio])

  useEffect(() => {
    const handleEnded = () => setPlaying(false)
    audio.addEventListener('ended', handleEnded)

    return () => {
      audio.removeEventListener('ended', handleEnded)
    }
  }, [audio])

  return (
    <div style={{ display: 'flex' }}>
      <audio controls>
        <source src={url} type="audio/mpeg" />
        El navegador no soporta el elemento de audio.
      </audio>
    </div>
  )
}

export default AudioMessagePlayer
