import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetUsers,
} from "../../helpers/backend_helper";


export const getUsers = createAsyncThunk("users/getUsersList", async ({ storeId, ...params }: any) => {
    try {
        const response = apiGetUsers({ storeId, ...params });
        return response;
    } catch (error) {
        return error;
    }
});